import { Avatar, Box, Grid, IconButton, Tooltip, Typography, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import {
  WarningAmberRounded,
  Lock,
  EventBusy,
  EventRepeat,
  Feed,
  ArrowForwardIos,
  ArrowBackIos
} from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { exibeValorLocalizado, exibeValorResumido, mascararCpfCnpj } from '../../../../shared';
// import imagemAvatar from '../../../../assets/img/default-avatar.png';
import CorrenteAberta from '../../../../assets/img/corrente_aberta.svg';
import CorrenteFechada from '../../../../assets/img/corrente_fechada.svg';
import CorrenteFechadaBranca from '../../../../assets/img/corrente_fechada_branca.svg';
import { nivelUsuarioEnum } from '../../../../shared/Enums';
import { retornaInformacoesNasPillsDeCheckpoints } from '../../../Onboarding/shared/Utils';

const limiteRiscoDisponivel = (params, siglaProd, nomeColuna, dadosUser) => {
  let tipoProdColuna = nomeColuna.split('_');
  let tipoProd = tipoProdColuna[1];
  var coluna = tipoProdColuna[0];
  var produtosConjunto = params.row && params.row.produtosConjunto ? params.row.produtosConjunto : [];
  var valor = params.value && parseInt(params.value) !== 0 ? parseInt(params.value) : 0;
  let negativa = false;
  if (valor < 0) {
    valor = parseFloat(String(valor).replace('-', ''));
    negativa = true;
  }
  var statusLim =
    coluna !== 'risco' && params.row && params.row['status_' + tipoProd]
      ? params.row['status_' + tipoProd]
      : 'ok';
  return (
    <>
      {valor !== 0 ? (
        <Box className="texto-nowrap-overflow-dotted">
          <span
            title={'R$ ' + (negativa ? '-' : '') + exibeValorLocalizado(valor)}
            className={
              negativa || (coluna !== 'risco' && (statusLim === 'bloqueado' || statusLim === 'vencido'))
                ? 'texto-danger'
                : ''
            }
          >
            {(negativa ? '-' : '') + exibeValorResumido(valor)}
          </span>
          {siglaProd && produtosConjunto.includes(siglaProd) && (
            <img
              src={CorrenteFechada}
              style={{ width: 12, height: 12, marginLeft: 5, marginTop: -5 }}
              title="Valor do conjunto"
              alt="Valor do conjunto"
            />
          )}
          {statusLim === 'bloqueado' &&
            coluna !== 'risco' &&
            dadosUser &&
            dadosUser.nivel !== nivelUsuarioEnum.CLIENTE && (
              <span title="Limite Bloqueado">
                <Lock className="texto-danger ajustar-icone-direita" />
              </span>
            )}
          {statusLim === 'vencido' && coluna !== 'risco' && (
            <span title="Limite Vencido">
              <EventBusy className="texto-danger ajustar-icone-direita" />
            </span>
          )}
          {statusLim === 'vai_vencer' && coluna !== 'risco' && (
            <span title="Limite próximo do vencimento">
              <EventRepeat className="texto-warning ajustar-icone-direita" />
            </span>
          )}
        </Box>
      ) : (
        <Box>
          <HorizontalRuleIcon className="texto-cor-cinza-claro" />
        </Box>
      )}
    </>
  );
};

const obterTituloMedia = (dados, tipo, produto) => {
  var titulo = '';
  if (dados && dados.dadosMedias && dados.dadosMedias[tipo]) {
    if (
      produto !== 'total' &&
      dados.dadosMedias[tipo].mediaProdutos &&
      dados.dadosMedias[tipo].mediaProdutos[produto]
    ) {
      titulo = 'A media é de R$ ' + exibeValorLocalizado(dados.dadosMedias[tipo].mediaProdutos[produto]);
    } else if (produto === 'total' && dados.dadosMedias[tipo].mediaTotal) {
      titulo = 'A media total é de R$ ' + exibeValorLocalizado(dados.dadosMedias[tipo].mediaTotal);
    }

    if (produto !== 'total' && dados.dadosMedias[tipo].qtd && dados.dadosMedias[tipo].qtd[produto]) {
      titulo += ' referente a ' + dados.dadosMedias[tipo].qtd[produto] + ' clientes.';
    } else if (produto === 'total' && dados.dadosMedias[tipo].qtdTotal) {
      titulo += ' referente a ' + dados.dadosMedias[tipo].qtdTotal + ' clientes.';
    }
  }
  return titulo;
};

const CheckpointActionsCell = ({ row, clickCheckpoints, clickParecer, clickLimiteComercial }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckpointClick = () => {
    clickCheckpoints(row);
    handleClose();
  };

  const handleParecerClick = () => {
    clickParecer(row);
    handleClose();
  };

  const handleLimiteComercialClick = () => {
    clickLimiteComercial(row);
    handleClose();
  };

  return (
    <Grid container sx={{ height: '56px' }}>
      <Grid item xs={12} className="flex-box flex-box-items-centralizados pad-top-5">
        <IconButton onClick={handleClick} sx={{ p: '0 2px', color: '#1976d2' }}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuItem onClick={handleCheckpointClick}>Checkpoint de negócios</MenuItem>
          <MenuItem onClick={handleParecerClick}>Parecer</MenuItem>
          <MenuItem onClick={handleLimiteComercialClick}>Limite comercial</MenuItem>
        </Menu>
      </Grid>
      {row.checkpoint_descricao && (
        <Box
          className="flex-box badge-padrao-proposta-cliente-checkpoint fundo-cor-complementar texto-cor-branca"
          title={retornaInformacoesNasPillsDeCheckpoints(
            row.data_insercao,
            row.checkpoint_descricao,
            row.nome_usuario_insercao,
            row.checkpoint_observacao
          )}
        >
          {row.checkpoint_descricao}
        </Box>
      )}
    </Grid>
  );
};

export const definicaoDeColunas = ({
  clickCheckpoints,
  clickLimiteComercial,
  clickGrupoEconomico,
  clickStatus,
  clickParecer,
  filtroDashboard,
  dadosUser,
  expandeRetraiFunc,
  expandirRetrairGrid,
  dadosAdicionaisGrid
}) => {
  var configColumns = [
    {
      field: 'grupoEconomico',
      headerName: 'Grupo',
      minWidth: 50,
      maxWidth: 60,
      flex: 1,
      headerClassName: 'data-grid-light-blue border-2-right-white font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-blue border-2-right-white',
      sortComparator: (v1, v2) => {
        v1 = v1 ? v1 : 'zzzzzzz';
        v2 = v2 ? v2 : 'zzzzzzz';
        return v1.localeCompare(v2, 'pt-BR');
      },
      sortingOrder: ['asc', 'desc'],
      renderCell: params => {
        return (
          <>
            <Grid container sx={{ height: '56px' }}>
              <Grid item xs={12} className="flex-box-items-centralizados pad-top-5">
                <IconButton
                  onClick={() => clickGrupoEconomico(params.row)}
                  id="btn-grupo-economico"
                  className="icon-button-contained"
                  disabled={params.value ? false : true}
                  size="small"
                >
                  {params.value ? (
                    <>
                      <Avatar id="chain" src={CorrenteFechada} sx={{ width: 22, height: 22 }} />
                      <Avatar
                        id="chain_white"
                        src={CorrenteFechadaBranca}
                        sx={{ width: 22, height: 22 }}
                      />
                    </>
                  ) : (
                    <Avatar src={CorrenteAberta} sx={{ width: 26, height: 26 }} />
                  )}
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                className="flex-box-items-centralizados pad-top-5"
                title={params.value || ''}
              >
                <Typography
                  variant="subtitle1"
                  className="texto-cor-preto font-size-0-7rem texto-hidden-overflow"
                >
                  {params.value ? (
                    params.value
                  ) : (
                    <HorizontalRuleIcon className="texto-cor-cinza-claro font-size-1-3-rem margin-neg-top-10" />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </>
        );
      }
    },
    {
      field: 'nomeCliente',
      headerName: 'Empresa',
      minWidth: 135,
      flex: 1,
      headerClassName: 'data-grid-blue border-2-right-white font-bold-header',
      cellClassName: 'data-grid-blue border-2-right-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR'),
      sortingOrder: ['asc', 'desc'],
      renderCell: params => criarClienteNomeCnpj(params)
    },
    {
      field: 'porteCliente',
      headerName: 'Porte',
      maxWidth: 90,
      flex: 1,
      headerClassName: 'data-grid-light-blue border-2-right-white font-bold-header',
      cellClassName: 'items-centralizados font-size-0-8-rem data-grid-light-blue border-2-right-white',
      sortComparator: (v1, v2) => v1 - v2,
      sortingOrder: ['asc', 'desc'],
      renderHeader: () => (
        <Box title="Porte do Cliente" className="texto-nowrap-overflow-dotted">
          Porte
        </Box>
      ),
      renderCell: params => {
        return (
          <>
            {params.value !== null ? (
              <Box>
                <Box>{`EMP ${params.value !== null ? params.value : ''}`}</Box>
              </Box>
            ) : (
              <Box>
                <HorizontalRuleIcon className="texto-cor-cinza-claro" />
              </Box>
            )}
          </>
        );
      }
    },
    {
      field: 'limite_normal',
      headerName: 'NO',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-blue',
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'NO', 'limite_normal', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'limite_comissaria',
      headerName: 'CM',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: `data-grid-light-blue font-bold-header ${
        expandirRetrairGrid ? 'border-2-right-white' : ''
      } `,
      cellClassName: `items-centralizados data-grid-light-blue ${
        expandirRetrairGrid ? 'border-2-right-white' : ''
      } `,
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'CM', 'limite_comissaria', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'limite_intercompany',
      headerName: 'IC',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-blue',
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'IC', 'limite_intercompany', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'limite_giro_rapido',
      headerName: 'GR',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-blue',
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'GR', 'limite_giro_rapido', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'limite_giro_parcelado',
      headerName: 'GP',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-blue',
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'GP', 'limite_giro_parcelado', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'limite_conta_escrow',
      headerName: 'SC',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-blue',
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'SC', 'limite_conta_escrow', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'limite_escrow_simples',
      headerName: 'ES',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold-header border-2-right-white',
      cellClassName: 'items-centralizados data-grid-light-blue border-2-right-white',
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'ES', 'limite_escrow_simples', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'risco_normal',
      headerName: 'NO',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-blue',
      description: obterTituloMedia(dadosAdicionaisGrid, 'risco', 'normal'),
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'NO', 'risco_normal', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'risco_comissaria',
      headerName: 'CM',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: `data-grid-light-blue font-bold-header ${
        expandirRetrairGrid ? 'border-2-right-white' : ''
      } `,
      cellClassName: `items-centralizados data-grid-light-blue ${
        expandirRetrairGrid ? 'border-2-right-white' : ''
      } `,
      description: obterTituloMedia(dadosAdicionaisGrid, 'risco', 'comissaria'),
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'CM', 'risco_comissaria', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'risco_intercompany',
      headerName: 'IC',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-blue',
      description: obterTituloMedia(dadosAdicionaisGrid, 'risco', 'intercompany'),
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'IC', 'risco_intercompany', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'risco_giro_rapido',
      headerName: 'GR',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-blue',
      description: obterTituloMedia(dadosAdicionaisGrid, 'risco', 'giro_rapido'),
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'GR', 'risco_giro_rapido', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'risco_giro_parcelado',
      headerName: 'GP',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-blue',
      description: obterTituloMedia(dadosAdicionaisGrid, 'risco', 'giro_parcelado'),
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'GP', 'risco_giro_parcelado', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'risco_conta_escrow',
      headerName: 'SC',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-blue',
      description: obterTituloMedia(dadosAdicionaisGrid, 'risco', 'conta_escrow'),
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'SC', 'risco_conta_escrow', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'risco_escrow_simples',
      headerName: 'ES',
      minWidth: 90,
      maxWidth: 115,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold-header border-2-right-white',
      cellClassName: 'items-centralizados data-grid-light-blue border-2-right-white',
      description: obterTituloMedia(dadosAdicionaisGrid, 'risco', 'escrow_simples'),
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'ES', 'risco_escrow_simples', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'disponivel_normal',
      headerName: 'NO',
      minWidth: 100,
      maxWidth: 125,
      flex: 1,
      headerClassName: 'data-grid-blue font-bold-header',
      cellClassName: 'items-centralizados data-grid-blue',
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'NO', 'disponivel_normal', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'disponivel_comissaria',
      headerName: 'CM',
      minWidth: 100,
      maxWidth: 125,
      flex: 1,
      headerClassName: `data-grid-blue font-bold-header ${
        expandirRetrairGrid ? 'border-2-right-white' : ''
      } `,
      cellClassName: `items-centralizados data-grid-blue ${
        expandirRetrairGrid ? 'border-2-right-white' : ''
      } `,
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'CM', 'disponivel_comissaria', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'disponivel_intercompany',
      headerName: 'IC',
      minWidth: 100,
      maxWidth: 125,
      flex: 1,
      headerClassName: 'data-grid-blue font-bold-header',
      cellClassName: 'items-centralizados data-grid-blue',
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'IC', 'disponivel_intercompany', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'disponivel_giro_rapido',
      headerName: 'GR',
      minWidth: 100,
      maxWidth: 125,
      flex: 1,
      headerClassName: 'data-grid-blue font-bold-header',
      cellClassName: 'items-centralizados data-grid-blue',
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'GR', 'disponivel_giro_rapido', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'disponivel_giro_parcelado',
      headerName: 'GP',
      minWidth: 100,
      maxWidth: 125,
      flex: 1,
      headerClassName: 'data-grid-blue font-bold-header',
      cellClassName: 'items-centralizados data-grid-blue',
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'GP', 'disponivel_giro_parcelado', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'disponivel_conta_escrow',
      headerName: 'SC',
      minWidth: 100,
      maxWidth: 125,
      flex: 1,
      headerClassName: 'data-grid-blue font-bold-header',
      cellClassName: 'items-centralizados data-grid-blue',
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'SC', 'disponivel_conta_escrow', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'disponivel_escrow_simples',
      headerName: 'ES',
      minWidth: 100,
      maxWidth: 125,
      flex: 1,
      headerClassName: 'data-grid-blue font-bold-header border-2-right-white',
      cellClassName: 'items-centralizados data-grid-blue border-2-right-white',
      sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
      sortingOrder: ['desc', 'asc'],
      renderCell: params => limiteRiscoDisponivel(params, 'ES', 'disponivel_escrow_simples', dadosUser),
      valueFormatter: params => parseInt(params.value)
    },
    {
      field: 'dias_ultima_operacao',
      headerName: 'Churn',
      renderHeader: () => (
        <Box title="Última Operação (dias)" className="texto-nowrap-overflow-dotted">
          Churn
        </Box>
      ),
      minWidth: 65,
      maxWidth: 95,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold border-2-right-white',
      sortComparator: (v1, v2) => {
        v1 = v1 !== '-' && v1 !== null ? parseInt(v1) : 0;
        v2 = v2 !== '-' && v2 !== null ? parseInt(v2) : 0;
        return v1 - v2;
      },
      sortingOrder: ['desc', 'asc'],
      cellClassName: params => {
        let classes =
          'items-centralizados font-bold font-size-1-1-rem data-grid-light-blue border-2-right-white ';
        if (params.value === '-') {
          classes += 'texto-cor-cinza-claro';
        } else {
          let dias = parseInt(params.value);
          if (dias <= 30) classes += 'texto-success';
          else if (dias > 30 && dias <= 120) classes += 'texto-warning';
          else if (dias > 120) classes += 'texto-danger';
        }
        return classes;
      },
      renderCell: params => {
        return (
          <Box>
            <Box>{params.value}</Box>
            <Box className="font-size-0-7rem texto-cor-cinza-escuro">
              {params.row.data_ultima_operacao}
            </Box>
          </Box>
        );
      }
    },
    {
      field: 'tipoStatus',
      headerName: '',
      minWidth: dadosUser && dadosUser.nivel !== nivelUsuarioEnum.CLIENTE ? 60 : 45,
      maxWidth: dadosUser && dadosUser.nivel !== nivelUsuarioEnum.CLIENTE ? 80 : 65,
      flex: 1,
      headerClassName: 'data-grid-light-blue font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-blue',
      sortComparator: (v1, v2) => {
        v1 = v1 === '' ? 1 : v1 === 'danger' ? 3 : 2;
        v2 = v2 === '' ? 1 : v2 === 'danger' ? 3 : 2;
        return v1 - v2;
      },
      sortingOrder: ['desc', 'asc'],
      renderCell: params => {
        return (
          <>
            {params.row.status && params.value && ['danger', 'warning'].includes(params.value) ? (
              <IconButton onClick={() => clickStatus(params.row)} sx={{ p: '0 2px' }}>
                <WarningAmberRounded className={'texto-' + params.value} />
              </IconButton>
            ) : (
              <HorizontalRuleIcon className="texto-cor-cinza-claro" sx={{ m: '0 2px' }} />
            )}
            {dadosUser &&
              dadosUser.nivel !== nivelUsuarioEnum.CLIENTE &&
              dadosUser.tipoOnboardingPlataforma !== 'connect' &&
              dadosUser.tipoOnboardingPlataforma !== 'indicantes' && (
                <IconButton
                  onClick={() => clickParecer(params.row)}
                  sx={{ p: '0 2px', color: '#1976d2' }}
                >
                  <Feed />
                </IconButton>
              )}
          </>
        );
      }
    },
    {
      field: 'esconderColunas',
      headerName: '',
      sortable: false,
      editable: true,
      minWidth: 40,
      maxWidth: 45,
      flex: 1,
      headerClassName: 'data-grid-light-blue border-2-right-white font-bold-header',
      cellClassName: 'data-grid-light-blue border-2-right-white',
      renderHeader: () => (
        <IconButton onClick={() => expandeRetraiFunc()} sx={{ p: '0 2px', color: '#1976d2' }}>
          <Tooltip
            arrow
            placement="top"
            title={`Ver ${expandirRetrairGrid ? 'mais' : 'menos'} produtos`}
          >
            <span>{expandirRetrairGrid ? <ArrowForwardIos /> : <ArrowBackIos />}</span>
          </Tooltip>
        </IconButton>
      )
    }
  ];

  if (dadosUser && dadosUser.nivel !== nivelUsuarioEnum.CLIENTE) {
    configColumns.unshift({
      field: 'nomeCheckpoint',
      headerName: 'Ações',
      minWidth: 80,
      maxWidth: 140,
      flex: 1,
      headerClassName: 'data-grid-light-blue border-2-right-white font-bold-header',
      cellClassName:
        'flex-box-items-centralizados items-centralizados data-grid-light-blue border-2-right-white',
      sortComparator: (v1, v2) => {
        v1 = v1 === '' ? 1 : v1 === 'danger' ? 3 : 2;
        v2 = v2 === '' ? 1 : v2 === 'danger' ? 3 : 2;
        return v1 - v2;
      },
      sortingOrder: ['desc', 'asc'],
      renderCell: params => (
        <CheckpointActionsCell
          row={params.row}
          clickCheckpoints={clickCheckpoints}
          clickParecer={clickParecer}
          clickLimiteComercial={clickLimiteComercial}
        />
      )
    });
  }

  if (filtroDashboard && filtroDashboard.gerente && filtroDashboard.gerente === 'todos') {
    configColumns.unshift({
      field: 'nomeGerente',
      headerName: 'Gerente',
      minWidth: 50,
      maxWidth: 66,
      flex: 1,
      headerClassName: 'data-grid-light-blue border-2-right-white font-bold-header',
      cellClassName: 'items-centralizados data-grid-light-blue border-2-right-white',
      sortComparator: (v1, v2) => v1.localeCompare(v2, 'pt-BR'),
      sortingOrder: ['asc', 'desc'],
      renderCell: params => criarGerenteAvatar(params)
    });
  }

  if (dadosUser && dadosUser.nivel !== nivelUsuarioEnum.CLIENTE) {
    configColumns.unshift({
      field: 'corSiga',
      headerName: '',
      flex: 1,
      minWidth: 15,
      maxWidth: 15,
      headerClassName: 'borda-cor-siga-head data-grid-light-blue border-2-right-white',
      cellClassName: 'borda-cor-siga',
      renderCell: params => (
        <Box
          title={`${params.row && params.row.corSiga ? params.row.corSiga.split('|')[0] : ''}`}
          className="texto-nowrap-overflow-dotted"
          sx={{
            borderLeft: `8px solid ${
              params.row && params.row.corSiga ? params.row.corSiga.split('|')[1] : ''
            }`,
            borderRadius: '5px',
            minHeight: '60px',
            width: '10px'
          }}
        />
      )
    });
  }

  if (dadosUser && dadosUser.nivel === nivelUsuarioEnum.CLIENTE) {
    configColumns = configColumns.filter(function(el) {
      return el.field !== 'porteCliente';
    });
  }

  return configColumns;
};

export const criarGerenteAvatar = params => {
  var tituloGerente = params.value;
  if (params.row && params.row.nomePlataforma)
    tituloGerente += '\nPlataf.: ' + params.row.nomePlataforma;
  if (params.row && params.row.nomeUnidadeNegocio)
    tituloGerente += '\nUnd.Neg.: ' + params.row.nomeUnidadeNegocio;
  return (
    <>
      {params.value && params.value === '-' ? (
        <HorizontalRuleIcon className="texto-cor-cinza-claro" />
      ) : (
        <Grid container title={tituloGerente}>
          <Grid item xs={12} className="flex-box-items-centralizados pad-top-5">
            <Typography
              variant="subtitle1"
              className="texto-cor-preto font-size-0-7rem texto-hidden-overflow"
            >
              {params.value}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const criarClienteNomeCnpj = params => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className="texto-left"
        title={params.value !== 'Total geral' ? params.value : ''}
      >
        <Typography
          className={
            params.value !== 'Total geral' ? 'texto-nowrap-overflow-dotted' : 'font-bold pad-topbot-20'
          }
        >
          {params.value}
        </Typography>
      </Grid>
      {params.row.cnpjCliente && (
        <Grid item xs={12} title={mascararCpfCnpj(params.row.cnpjCliente)}>
          <Typography
            variant="subtitle1"
            className="texto-nowrap-overflow-dotted font-size-0-8rem texto-cinza-medio"
          >
            {mascararCpfCnpj(params.row.cnpjCliente)}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export const definicaoGrupoDeColunas = ({
  filtroDashboard,
  dadosUser,
  expandirRetrairGrid,
  dadosAdicionaisGrid
}) => {
  let arrayLimites = [
    { field: 'limite_normal' },
    { field: 'limite_comissaria' },
    { field: 'limite_intercompany' },
    { field: 'limite_giro_rapido' },
    { field: 'limite_giro_parcelado' },
    { field: 'limite_conta_escrow' },
    { field: 'limite_escrow_simples' }
  ];

  let arrayRisco = [
    { field: 'risco_normal' },
    { field: 'risco_comissaria' },
    { field: 'risco_intercompany' },
    { field: 'risco_giro_rapido' },
    { field: 'risco_giro_parcelado' },
    { field: 'risco_conta_escrow' },
    { field: 'risco_escrow_simples' }
  ];

  let arrayDisponivel = [
    { field: 'disponivel_normal' },
    { field: 'disponivel_comissaria' },
    { field: 'disponivel_intercompany' },
    { field: 'disponivel_giro_rapido' },
    { field: 'disponivel_giro_parcelado' },
    { field: 'disponivel_conta_escrow' },
    { field: 'disponivel_escrow_simples' }
  ];

  if (expandirRetrairGrid) {
    arrayLimites = [{ field: 'limite_normal' }, { field: 'limite_comissaria' }];
    arrayRisco = [{ field: 'risco_normal' }, { field: 'risco_comissaria' }];
    arrayDisponivel = [{ field: 'disponivel_normal' }, { field: 'disponivel_comissaria' }];
  }

  var configGroupColumns = [
    {
      groupId: 'grp_grupoEconomico',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'grupoEconomico' }]
    },
    {
      groupId: 'grp_nomeCliente',
      headerClassName: 'data-grid-blue border-2-right-white',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'nomeCliente' }]
    },
    {
      groupId: 'porteCliente',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'porteCliente' }]
    },
    {
      groupId: 'Limite',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      children: arrayLimites
    },
    {
      groupId: 'Risco',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      description: obterTituloMedia(dadosAdicionaisGrid, 'risco', 'total'),
      children: arrayRisco
    },
    {
      groupId: 'Limite disponível',
      headerClassName: 'data-grid-blue border-2-right-white',
      children: arrayDisponivel
    },
    {
      groupId: 'grp_dias_ultima_operacao',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'dias_ultima_operacao' }]
    },
    {
      groupId: 'grp_status',
      headerClassName: 'data-grid-light-blue',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'tipoStatus' }]
    },
    {
      groupId: 'esconder_colunas',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'esconderColunas' }]
    }
  ];

  if (dadosUser && dadosUser.nivel !== nivelUsuarioEnum.CLIENTE) {
    configGroupColumns.unshift({
      groupId: 'grp_grupoCheckpoint',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'nomeCheckpoint' }]
    });
  }

  if (filtroDashboard && filtroDashboard.gerente && filtroDashboard.gerente === 'todos') {
    configGroupColumns.unshift({
      groupId: 'grp_nomeGerente',
      headerClassName: 'data-grid-light-blue border-2-right-white',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'nomeGerente' }]
    });
  }

  if (dadosUser && dadosUser.nivel !== nivelUsuarioEnum.CLIENTE) {
    configGroupColumns.unshift({
      groupId: 'cor_siga',
      headerClassName: 'header-cor-siga data-grid-light-blue border-2-right-white',
      renderHeaderGroup: () => <></>,
      children: [{ field: 'corSiga' }]
    });
  }

  if (dadosUser && dadosUser.nivel === nivelUsuarioEnum.CLIENTE) {
    configGroupColumns = configGroupColumns.filter(function(el) {
      return el.groupId !== 'porteCliente';
    });
  }

  return configGroupColumns;
};
