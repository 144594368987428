import React, { useEffect, useState } from 'react';
import { Box, Grid, Tooltip } from '@mui/material';
import { Chart } from 'react-google-charts';
import moment from 'moment';
import 'moment/locale/pt-br';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { EventBusy, EventRepeat, Lock } from '@mui/icons-material';
import { postLimitesCarteira, postLimitesComercialCarteira } from '../../../../api/dashboard';
import { exibeValorResumido, getClienteAtual } from '../../../../shared';
import { moneyFullFormat } from '../../../../utils/format';

const DashLimitesCarteira = ({
  filtroDashboard,
  loadingDashFim,
  parentAbaAtiva,
  parentSetIsPending,
  parentSetDadosChart
}) => {
  moment.locale('pt-br');
  const siglasProd = {
    normal: false,
    comissaria: false,
    intercompany: false,
    giro_rapido: false,
    giro_parcelado: false,
    conta_escrow: false,
    escrow_simples: false
  };

  const [dadosProdutos, setDadosProdutos] = useState({ ...siglasProd });
  const [limiteProdutos, setLimiteProdutos] = useState({ ...siglasProd });
  const [disponivelProdutos, setDisponivelProdutos] = useState({ ...siglasProd });
  const [dispNegativaProdutos, setDispNegativaProdutos] = useState({ ...siglasProd });
  const [possuiLimiteGrupo, setPossuiLimiteGrupo] = useState({ ...siglasProd });
  const [statusProdutos, setStatusProdutos] = useState({ ...siglasProd });
  const [cacheData, setCacheData] = useState({});

  const optionsNormal = {
    title: null,
    pieHole: 0.7,
    is3D: false,
    pieSliceText: 'none',
    legend: 'none',
    colors: ['#79BCFB', '#EFF1F6'],
    animation: {
      duration: 1000,
      easing: 'out'
    }
  };

  const optionsComissaria = {
    title: null,
    pieHole: 0.7,
    is3D: false,
    pieSliceText: 'none',
    legend: 'none',
    colors: ['#FFC784', '#fff7ed']
  };

  const optionsIntercompany = {
    title: null,
    pieHole: 0.7,
    is3D: false,
    pieSliceText: 'none',
    legend: 'none',
    colors: ['#F97A7B', '#ffebee']
  };

  const optionsGiroRapido = {
    title: null,
    pieHole: 0.7,
    is3D: false,
    pieSliceText: 'none',
    legend: 'none',
    colors: ['#5ad4ff', '#eff1f6']
  };

  const optionsGiroParcelado = {
    title: null,
    pieHole: 0.7,
    is3D: false,
    pieSliceText: 'none',
    legend: 'none',
    colors: ['#9753aa', '#f0e3f9']
  };

  const optionsContaScrow = {
    title: null,
    pieHole: 0.7,
    is3D: false,
    pieSliceText: 'none',
    legend: 'none',
    colors: ['#79BCFB', '#EFF1F6'],
    animation: {
      duration: 1000,
      easing: 'out'
    }
  };

  const optionsEscrowSimples = {
    title: null,
    pieHole: 0.7,
    is3D: false,
    pieSliceText: 'none',
    legend: 'none',
    colors: ['#FFC784', '#fff7ed']
  };

  const buscaLimitesCarteira = (tipoLimite, cache) => {
    const cliente = getClienteAtual();
    const filtro = filtroDashboard || {};

    if (cache && cacheData[tipoLimite]) {
      const cached = cacheData[tipoLimite];
      setDadosProdutos(cached.dadosProdutos);
      setLimiteProdutos(cached.limiteProdutos);
      setDisponivelProdutos(cached.disponivelProdutos);
      setDispNegativaProdutos(cached.dispNegativaProdutos);
      if (cliente.id !== 'todos') {
        setStatusProdutos(cached.statusProdutos);
      }
      return;
    }

    parentSetIsPending(true);

    const postLimiteDashboard =
      tipoLimite === 'comercial' ? postLimitesComercialCarteira : postLimitesCarteira;

    postLimiteDashboard(filtro)
      .then(res => {
        const result = res && res.data ? res.data : [];
        const lstProdutos = result.lista ? result.lista : [];
        const dadosStates = {
          dadosProds: { ...siglasProd },
          limProds: { ...siglasProd },
          dispoProds: { ...siglasProd },
          dispoNegatProds: { ...siglasProd },
          statusProd: { ...siglasProd }
        };

        if (lstProdutos.length > 0) {
          lstProdutos.forEach(prodDados => {
            const produto = prodDados.PRODUTO;
            let disponivel = parseFloat(prodDados.DISPONIVEL);
            let disponivelTratada = disponivel;
            let negativa = false;
            const limiteProd = parseFloat(prodDados.LIMITE);

            if (disponivel < 0) {
              disponivelTratada = parseFloat(String(disponivel).replace('-', ''));
              disponivel = 0;
              negativa = true;
            }

            dadosStates.dadosProds[produto] = [
              ['Limite', 'Disponível'],
              ['Risco', parseFloat(prodDados.RISCO)],
              [limiteProd !== 0 ? 'Disponível' : '---', limiteProd !== 0 ? disponivel : 0.01]
            ];
            dadosStates.limProds[produto] = limiteProd;
            dadosStates.dispoProds[produto] = disponivelTratada;
            dadosStates.dispoNegatProds[produto] = negativa;
            dadosStates.statusProd[produto] = prodDados.STATUS;
          });
        }

        setDadosProdutos({ ...dadosStates.dadosProds });
        setLimiteProdutos({ ...dadosStates.limProds });
        setDisponivelProdutos({ ...dadosStates.dispoProds });
        setDispNegativaProdutos({ ...dadosStates.dispoNegatProds });
        if (cliente.id !== 'todos') {
          setStatusProdutos({ ...dadosStates.statusProd });
        }

        setCacheData(prevCache => ({
          ...prevCache,
          [tipoLimite]: {
            dadosProdutos: { ...dadosStates.dadosProds },
            limiteProdutos: { ...dadosStates.limProds },
            disponivelProdutos: { ...dadosStates.dispoProds },
            dispNegativaProdutos: { ...dadosStates.dispoNegatProds },
            statusProdutos: { ...dadosStates.statusProd }
          }
        }));

        if (result.produtosConjunto) {
          const produtosConjunto = result.produtosConjunto;
          const limGrupo = {
            normal: produtosConjunto.normal || false,
            comissaria: produtosConjunto.comissaria || false,
            intercompany: produtosConjunto.intercompany || false,
            giro_rapido: produtosConjunto.giro_rapido || false,
            giro_parcelado: produtosConjunto.giro_parcelado || false,
            conta_escrow: produtosConjunto.conta_escrow || false,
            escrow_simples: produtosConjunto.escrow_simples || false
          };
          setPossuiLimiteGrupo(limGrupo);
        } else {
          setPossuiLimiteGrupo({ ...siglasProd });
        }
      })
      .catch(() => {
        parentSetDadosChart(false);
        setDadosProdutos({ ...siglasProd });
      })
      .finally(() => {
        parentSetIsPending(false);
        loadingDashFim('DashLimitesCarteira');
      });
  };
  useEffect(() => {
    if (Object.keys(cacheData).length > 0) {
      buscaLimitesCarteira(parentAbaAtiva, true);
    }
  }, [parentAbaAtiva]);

  useEffect(() => {
    setCacheData({});
    buscaLimitesCarteira(parentAbaAtiva, false);
  }, [filtroDashboard]);

  return (
    <Grid
      className="gridLimitesCarteira"
      container
      sx={
        possuiLimiteGrupo.normal || possuiLimiteGrupo.comissaria || possuiLimiteGrupo.intercompany
          ? {}
          : { marginTop: '20px' }
      }
    >
      <Grid className="grid-item-limites-carteira" item lg={2} md={2} sm={6} xs={12}>
        {dadosProdutos.normal ? (
          <>
            <Box className="limite-carteira-label">
              {statusProdutos.normal && statusProdutos.normal.vai_vencer && (
                <Tooltip
                  arrow
                  placement="top"
                  title="Limite próximo do vencimento, contate seu Gerente."
                >
                  <EventRepeat className="texto-warning margin-5 font-size-1-2-rem posicao-absoluta-esquerda" />
                </Tooltip>
              )}
              Normal
            </Box>
            <Box className="chart-pie-hole-margin-value">
              <Box
                className={dispNegativaProdutos.normal ? 'margin-value texto-danger' : 'margin-value'}
              >
                {statusProdutos.normal && statusProdutos.normal.bloqueado ? (
                  <Tooltip arrow placement="top" title="Limite Bloqueado, contate seu Gerente.">
                    <Lock className="texto-danger" />
                  </Tooltip>
                ) : statusProdutos.normal && statusProdutos.normal.vencido ? (
                  <Tooltip arrow placement="top" title="Limite Vencido, contate seu Gerente.">
                    <EventBusy className="texto-danger" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    arrow
                    placement="top"
                    title={String(
                      String(dispNegativaProdutos.normal ? 'R$ -' : 'R$ ') +
                        disponivelProdutos.normal.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                    )}
                  >
                    <span>
                      {dispNegativaProdutos.normal ? '-' : ''}
                      {exibeValorResumido(disponivelProdutos.normal)}
                    </span>
                  </Tooltip>
                )}
              </Box>
              <Box className="margin-label">
                {statusProdutos.normal && statusProdutos.normal.bloqueado
                  ? 'Bloqueado'
                  : statusProdutos.normal && statusProdutos.normal.vencido
                  ? 'Vencido'
                  : 'Disponível'}
              </Box>
            </Box>
            <Chart
              className="chartLimiteCarteira"
              chartType="PieChart"
              width="100%"
              height="200px"
              data={dadosProdutos.normal}
              options={optionsNormal}
              chartLanguage="pt-Br"
            />
            <Box className="footerLimite">
              <Box className="labelLimite">
                {possuiLimiteGrupo.normal && (
                  <Tooltip
                    arrow
                    placement="top"
                    title="Esse limite é compartilhado entre as outras empresas do grupo."
                  >
                    <span>
                      <InfoIcon className="info-icon-limite" />
                    </span>
                  </Tooltip>
                )}
                Limite
              </Box>
              <Box className="labelLimiteValor">
                <Tooltip arrow placement="top" title={moneyFullFormat(limiteProdutos.normal)}>
                  <span>{exibeValorResumido(limiteProdutos.normal)}</span>
                </Tooltip>
              </Box>
            </Box>
          </>
        ) : (
          <Box className="semLimiteProduto">- - -</Box>
        )}
      </Grid>
      <Grid className="grid-item-limites-carteira" item lg={2} md={2} sm={6} xs={12}>
        {dadosProdutos.comissaria ? (
          <>
            <Box className="limite-carteira-label">
              {statusProdutos.comissaria && statusProdutos.comissaria.vai_vencer && (
                <Tooltip
                  arrow
                  placement="top"
                  title="Limite próximo do vencimento, contate seu Gerente."
                >
                  <EventRepeat className="texto-warning margin-5 font-size-1-2-rem posicao-absoluta-esquerda" />
                </Tooltip>
              )}
              Comissária
            </Box>
            <Box className="chart-pie-hole-margin-value">
              <Box
                className={
                  dispNegativaProdutos.comissaria ? 'margin-value texto-danger' : 'margin-value'
                }
              >
                {statusProdutos.comissaria && statusProdutos.comissaria.bloqueado ? (
                  <Tooltip arrow placement="top" title="Limite Bloqueado, contate seu Gerente.">
                    <Lock className="texto-danger" />
                  </Tooltip>
                ) : statusProdutos.comissaria && statusProdutos.comissaria.vencido ? (
                  <Tooltip arrow placement="top" title="Limite Vencido, contate seu Gerente.">
                    <EventBusy className="texto-danger" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    arrow
                    placement="top"
                    title={String(
                      String(dispNegativaProdutos.comissaria ? 'R$ -' : 'R$ ') +
                        disponivelProdutos.comissaria.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                    )}
                  >
                    <span>
                      {dispNegativaProdutos.comissaria ? '-' : ''}
                      {exibeValorResumido(disponivelProdutos.comissaria)}
                    </span>
                  </Tooltip>
                )}
              </Box>
              <Box className="margin-label">
                {statusProdutos.comissaria && statusProdutos.comissaria.bloqueado
                  ? 'Bloqueado'
                  : statusProdutos.comissaria && statusProdutos.comissaria.vencido
                  ? 'Vencido'
                  : 'Disponível'}
              </Box>
            </Box>
            <Chart
              className="chartLimiteCarteira"
              chartType="PieChart"
              width="100%"
              height="200px"
              data={dadosProdutos.comissaria}
              options={optionsComissaria}
              chartLanguage="pt-Br"
            />
            <Box className="footerLimite">
              <Box className="labelLimite">
                {possuiLimiteGrupo.comissaria && (
                  <Tooltip
                    arrow
                    placement="top"
                    title="Esse limite é compartilhado entre as outras empresas do grupo."
                  >
                    <span>
                      <InfoIcon className="info-icon-limite" />
                    </span>
                  </Tooltip>
                )}
                Limite
              </Box>
              <Box className="labelLimiteValor">
                <Tooltip arrow placement="top" title={moneyFullFormat(limiteProdutos.comissaria)}>
                  <span>{exibeValorResumido(limiteProdutos.comissaria)}</span>
                </Tooltip>
              </Box>
            </Box>
          </>
        ) : (
          <Box className="semLimiteProduto">- - -</Box>
        )}
      </Grid>
      <Grid className="grid-item-limites-carteira" item lg={2} md={2} sm={6} xs={12}>
        {dadosProdutos.intercompany ? (
          <>
            <Box className="limite-carteira-label">
              {statusProdutos.intercompany && statusProdutos.intercompany.vai_vencer && (
                <Tooltip
                  arrow
                  placement="top"
                  title="Limite próximo do vencimento, contate seu Gerente."
                >
                  <EventRepeat className="texto-warning margin-5 font-size-1-2-rem posicao-absoluta-esquerda" />
                </Tooltip>
              )}
              Intercompany
            </Box>
            <Box className="chart-pie-hole-margin-value">
              <Box
                className={
                  dispNegativaProdutos.intercompany ? 'margin-value texto-danger' : 'margin-value'
                }
              >
                {statusProdutos.intercompany && statusProdutos.intercompany.bloqueado ? (
                  <Tooltip arrow placement="top" title="Limite Bloqueado, contate seu Gerente.">
                    <Lock className="texto-danger" />
                  </Tooltip>
                ) : statusProdutos.intercompany && statusProdutos.intercompany.vencido ? (
                  <Tooltip arrow placement="top" title="Limite Vencido, contate seu Gerente.">
                    <EventBusy className="texto-danger" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    arrow
                    placement="top"
                    title={String(
                      String(dispNegativaProdutos.intercompany ? 'R$ -' : 'R$ ') +
                        disponivelProdutos.intercompany.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                    )}
                  >
                    <span>
                      {dispNegativaProdutos.intercompany ? '-' : ''}
                      {exibeValorResumido(disponivelProdutos.intercompany)}
                    </span>
                  </Tooltip>
                )}
              </Box>
              <Box className="margin-label">
                {statusProdutos.intercompany && statusProdutos.intercompany.bloqueado
                  ? 'Bloqueado'
                  : statusProdutos.intercompany && statusProdutos.intercompany.vencido
                  ? 'Vencido'
                  : 'Disponível'}
              </Box>
            </Box>
            <Chart
              className="chartLimiteCarteira"
              chartType="PieChart"
              width="100%"
              height="200px"
              data={dadosProdutos.intercompany}
              options={optionsIntercompany}
              chartLanguage="pt-Br"
            />
            <Box className="footerLimite">
              <Box className="labelLimite">
                {possuiLimiteGrupo.intercompany && (
                  <Tooltip
                    arrow
                    placement="top"
                    title="Esse limite é compartilhado entre as outras empresas do grupo."
                  >
                    <span>
                      <InfoIcon className="info-icon-limite" />
                    </span>
                  </Tooltip>
                )}
                Limite
              </Box>
              <Box className="labelLimiteValor">
                <Tooltip arrow placement="top" title={moneyFullFormat(limiteProdutos.intercompany)}>
                  <span>{exibeValorResumido(limiteProdutos.intercompany)}</span>
                </Tooltip>
              </Box>
            </Box>
          </>
        ) : (
          <Box className="semLimiteProduto">- - -</Box>
        )}
      </Grid>
      <Grid className="grid-item-limites-carteira" item lg={2} md={2} sm={6} xs={12}>
        {dadosProdutos.giro_rapido ? (
          <>
            <Box className="limite-carteira-label">
              {statusProdutos.giro_rapido && statusProdutos.giro_rapido.vai_vencer && (
                <Tooltip
                  arrow
                  placement="top"
                  title="Limite próximo do vencimento, contate seu Gerente."
                >
                  <EventRepeat className="texto-warning margin-5 font-size-1-2-rem posicao-absoluta-esquerda" />
                </Tooltip>
              )}
              Giro Rápido
            </Box>
            <Box className="chart-pie-hole-margin-value">
              <Box
                className={
                  dispNegativaProdutos.giro_rapido ? 'margin-value texto-danger' : 'margin-value'
                }
              >
                {statusProdutos.giro_rapido && statusProdutos.giro_rapido.bloqueado ? (
                  <Tooltip arrow placement="top" title="Limite Bloqueado, contate seu Gerente.">
                    <Lock className="texto-danger" />
                  </Tooltip>
                ) : statusProdutos.giro_rapido && statusProdutos.giro_rapido.vencido ? (
                  <Tooltip arrow placement="top" title="Limite Vencido, contate seu Gerente.">
                    <EventBusy className="texto-danger" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    arrow
                    placement="top"
                    title={String(
                      String(dispNegativaProdutos.giro_rapido ? 'R$ -' : 'R$ ') +
                        disponivelProdutos.giro_rapido.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                    )}
                  >
                    <span>
                      {dispNegativaProdutos.giro_rapido ? '-' : ''}
                      {exibeValorResumido(disponivelProdutos.giro_rapido)}
                    </span>
                  </Tooltip>
                )}
              </Box>
              <Box className="margin-label">
                {statusProdutos.giro_rapido && statusProdutos.giro_rapido.bloqueado
                  ? 'Bloqueado'
                  : statusProdutos.giro_rapido && statusProdutos.giro_rapido.vencido
                  ? 'Vencido'
                  : 'Disponível'}
              </Box>
            </Box>
            <Chart
              className="chartLimiteCarteira"
              chartType="PieChart"
              width="100%"
              height="200px"
              data={dadosProdutos.giro_rapido}
              options={optionsGiroRapido}
              chartLanguage="pt-Br"
            />
            <Box className="footerLimite">
              <Box className="labelLimite">
                {possuiLimiteGrupo.giro_rapido && (
                  <Tooltip
                    arrow
                    placement="top"
                    title="Esse limite é compartilhado entre as outras empresas do grupo."
                  >
                    <span>
                      <InfoIcon className="info-icon-limite" />
                    </span>
                  </Tooltip>
                )}
                Limite
              </Box>
              <Box className="labelLimiteValor">
                <Tooltip arrow placement="top" title={moneyFullFormat(limiteProdutos.giro_rapido)}>
                  <span>{exibeValorResumido(limiteProdutos.giro_rapido)}</span>
                </Tooltip>
              </Box>
            </Box>
          </>
        ) : (
          <Box className="semLimiteProduto">- - -</Box>
        )}
      </Grid>
      <Grid className="grid-item-limites-carteira" item lg={2} md={2} sm={6} xs={12}>
        {dadosProdutos.giro_parcelado ? (
          <>
            <Box className="limite-carteira-label">
              {statusProdutos.giro_parcelado && statusProdutos.giro_parcelado.vai_vencer && (
                <Tooltip
                  arrow
                  placement="top"
                  title="Limite próximo do vencimento, contate seu Gerente."
                >
                  <EventRepeat className="texto-warning margin-5 font-size-1-2-rem posicao-absoluta-esquerda" />
                </Tooltip>
              )}
              Giro Parcelado
            </Box>
            <Box className="chart-pie-hole-margin-value">
              <Box
                className={
                  dispNegativaProdutos.giro_parcelado ? 'margin-value texto-danger' : 'margin-value'
                }
              >
                {statusProdutos.giro_parcelado && statusProdutos.giro_parcelado.bloqueado ? (
                  <Tooltip arrow placement="top" title="Limite Bloqueado, contate seu Gerente.">
                    <Lock className="texto-danger" />
                  </Tooltip>
                ) : statusProdutos.giro_parcelado && statusProdutos.giro_parcelado.vencido ? (
                  <Tooltip arrow placement="top" title="Limite Vencido, contate seu Gerente.">
                    <EventBusy className="texto-danger" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    arrow
                    placement="top"
                    title={String(
                      String(dispNegativaProdutos.giro_parcelado ? 'R$ -' : 'R$ ') +
                        disponivelProdutos.giro_parcelado.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                    )}
                  >
                    <span>
                      {dispNegativaProdutos.giro_parcelado ? '-' : ''}
                      {exibeValorResumido(disponivelProdutos.giro_parcelado)}
                    </span>
                  </Tooltip>
                )}
              </Box>
              <Box className="margin-label">
                {statusProdutos.giro_parcelado && statusProdutos.giro_parcelado.bloqueado
                  ? 'Bloqueado'
                  : statusProdutos.giro_parcelado && statusProdutos.giro_parcelado.vencido
                  ? 'Vencido'
                  : 'Disponível'}
              </Box>
            </Box>
            <Chart
              className="chartLimiteCarteira"
              chartType="PieChart"
              width="100%"
              height="200px"
              data={dadosProdutos.giro_parcelado}
              options={optionsGiroParcelado}
              chartLanguage="pt-Br"
            />
            <Box className="footerLimite">
              <Box className="labelLimite">
                {possuiLimiteGrupo.giro_parcelado && (
                  <Tooltip
                    arrow
                    placement="top"
                    title="Esse limite é compartilhado entre as outras empresas do grupo."
                  >
                    <span>
                      <InfoIcon className="info-icon-limite" />
                    </span>
                  </Tooltip>
                )}
                Limite
              </Box>
              <Box className="labelLimiteValor">
                <Tooltip arrow placement="top" title={moneyFullFormat(limiteProdutos.giro_parcelado)}>
                  <span>{exibeValorResumido(limiteProdutos.giro_parcelado)}</span>
                </Tooltip>
              </Box>
            </Box>
          </>
        ) : (
          <Box className="semLimiteProduto">- - -</Box>
        )}
      </Grid>
      <Grid className="grid-item-limites-carteira" item lg={2} md={2} sm={6} xs={12}>
        {dadosProdutos.conta_escrow ? (
          <>
            <Box className="limite-carteira-label">
              {statusProdutos.conta_escrow && statusProdutos.conta_escrow.vai_vencer && (
                <Tooltip
                  arrow
                  placement="top"
                  title="Limite próximo do vencimento, contate seu Gerente."
                >
                  <EventRepeat className="texto-warning margin-5 font-size-1-2-rem posicao-absoluta-esquerda" />
                </Tooltip>
              )}
              Escrow Boleto
            </Box>
            <Box className="chart-pie-hole-margin-value">
              <Box
                className={
                  dispNegativaProdutos.conta_escrow ? 'margin-value texto-danger' : 'margin-value'
                }
              >
                {statusProdutos.conta_escrow && statusProdutos.conta_escrow.bloqueado ? (
                  <Tooltip arrow placement="top" title="Limite Bloqueado, contate seu Gerente.">
                    <Lock className="texto-danger" />
                  </Tooltip>
                ) : statusProdutos.conta_escrow && statusProdutos.conta_escrow.vencido ? (
                  <Tooltip arrow placement="top" title="Limite Vencido, contate seu Gerente.">
                    <EventBusy className="texto-danger" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    arrow
                    placement="top"
                    title={String(
                      String(dispNegativaProdutos.conta_escrow ? 'R$ -' : 'R$ ') +
                        disponivelProdutos.conta_escrow.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                    )}
                  >
                    <span>
                      {dispNegativaProdutos.conta_escrow ? '-' : ''}
                      {exibeValorResumido(disponivelProdutos.conta_escrow)}
                    </span>
                  </Tooltip>
                )}
              </Box>
              <Box className="margin-label">
                {statusProdutos.conta_escrow && statusProdutos.conta_escrow.bloqueado
                  ? 'Bloqueado'
                  : statusProdutos.conta_escrow && statusProdutos.conta_escrow.vencido
                  ? 'Vencido'
                  : 'Disponível'}
              </Box>
            </Box>
            <Chart
              className="chartLimiteCarteira"
              chartType="PieChart"
              width="100%"
              height="200px"
              data={dadosProdutos.conta_escrow}
              options={optionsContaScrow}
              chartLanguage="pt-Br"
            />
            <Box className="footerLimite">
              <Box className="labelLimite">
                {possuiLimiteGrupo.conta_escrow && (
                  <Tooltip
                    arrow
                    placement="top"
                    title="Esse limite é compartilhado entre as outras empresas do grupo."
                  >
                    <span>
                      <InfoIcon className="info-icon-limite" />
                    </span>
                  </Tooltip>
                )}
                Limite
              </Box>
              <Box className="labelLimiteValor">
                <Tooltip arrow placement="top" title={moneyFullFormat(limiteProdutos.conta_escrow)}>
                  <span>{exibeValorResumido(limiteProdutos.conta_escrow)}</span>
                </Tooltip>
              </Box>
            </Box>
          </>
        ) : (
          <Box className="semLimiteProduto">- - -</Box>
        )}
      </Grid>
      <Grid className="grid-item-limites-carteira" item lg={2} md={2} sm={6} xs={12}>
        {dadosProdutos.escrow_simples ? (
          <>
            <Box className="limite-carteira-label">
              {statusProdutos.escrow_simples && statusProdutos.escrow_simples.vai_vencer && (
                <Tooltip
                  arrow
                  placement="top"
                  title="Limite próximo do vencimento, contate seu Gerente."
                >
                  <EventRepeat className="texto-warning margin-5 font-size-1-2-rem posicao-absoluta-esquerda" />
                </Tooltip>
              )}
              Escrow Simples
            </Box>
            <Box className="chart-pie-hole-margin-value">
              <Box
                className={
                  dispNegativaProdutos.escrow_simples ? 'margin-value texto-danger' : 'margin-value'
                }
              >
                {statusProdutos.escrow_simples && statusProdutos.escrow_simples.bloqueado ? (
                  <Tooltip arrow placement="top" title="Limite Bloqueado, contate seu Gerente.">
                    <Lock className="texto-danger" />
                  </Tooltip>
                ) : statusProdutos.escrow_simples && statusProdutos.escrow_simples.vencido ? (
                  <Tooltip arrow placement="top" title="Limite Vencido, contate seu Gerente.">
                    <EventBusy className="texto-danger" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    arrow
                    placement="top"
                    title={String(
                      String(dispNegativaProdutos.escrow_simples ? 'R$ -' : 'R$ ') +
                        disponivelProdutos.escrow_simples.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                    )}
                  >
                    <span>
                      {dispNegativaProdutos.escrow_simples ? '-' : ''}
                      {exibeValorResumido(disponivelProdutos.escrow_simples)}
                    </span>
                  </Tooltip>
                )}
              </Box>
              <Box className="margin-label">
                {statusProdutos.escrow_simples && statusProdutos.escrow_simples.bloqueado
                  ? 'Bloqueado'
                  : statusProdutos.escrow_simples && statusProdutos.escrow_simples.vencido
                  ? 'Vencido'
                  : 'Disponível'}
              </Box>
            </Box>
            <Chart
              className="chartLimiteCarteira"
              chartType="PieChart"
              width="100%"
              height="200px"
              data={dadosProdutos.escrow_simples}
              options={optionsEscrowSimples}
              chartLanguage="pt-Br"
            />
            <Box className="footerLimite">
              <Box className="labelLimite">
                {possuiLimiteGrupo.escrow_simples && (
                  <Tooltip
                    arrow
                    placement="top"
                    title="Esse limite é compartilhado entre as outras empresas do grupo."
                  >
                    <span>
                      <InfoIcon className="info-icon-limite" />
                    </span>
                  </Tooltip>
                )}
                Limite
              </Box>
              <Box className="labelLimiteValor">
                <Tooltip arrow placement="top" title={moneyFullFormat(limiteProdutos.escrow_simples)}>
                  <span>{exibeValorResumido(limiteProdutos.escrow_simples)}</span>
                </Tooltip>
              </Box>
            </Box>
          </>
        ) : (
          <Box className="semLimiteProduto">- - -</Box>
        )}
      </Grid>
    </Grid>
  );
};

export default DashLimitesCarteira;
