import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  ptBR,
  GridToolbarQuickFilter
} from '@mui/x-data-grid';
import { Box, Grid, Switch } from '@mui/material';
import moment from 'moment';
import { postListaClientesDashboard } from '../../../../api/dashboard';
import LoaderMain from '../../../Onboarding/shared/LoaderMain';
import { definicaoDeColunas, definicaoGrupoDeColunas } from './definicoes-grid';
import ModalGridDashboard from './ModalGridDashboard';
import ButtonMain from '../../../Onboarding/shared/ButtonMain';
import { nivelUsuarioEnum } from '../../../../shared/Enums';
import ModalGridDashboardCheckpoints from '../ModalGridDashboardCheckpoints';
import ModalGridDashboardLimiteComercial from '../ModalGridDashboardLimiteComercial';

const ClientesDashboard = ({ filtroDashboard, loadingDashFim, dadosUser, clienteAtual, setAlerta }) => {
  const qtdPorPagina = 10;
  const [listaClientes, setListaClientes] = useState([]);
  const [dadosAdicionaisGrid, setDadosAdicionaisGrid] = useState({});
  const [qtdMaxPaginas, setQtdMaxPaginas] = useState(false);
  const [esconderFooter, setEsconderFooter] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [modalGrid, setModalGrid] = useState(false);
  const [definicoesGrid, setDefinicoesGrid] = useState({ colunas: {}, grupoColunas: {} });
  const [paginaSelecionada, setPaginaSelecionada] = useState(1);
  const [paginacaoModel, setPaginacaoModel] = useState({
    page: 0,
    pageSize: qtdPorPagina
  });
  const [expandirRetrairGrid, setExpandirRetrairGrid] = useState(true);
  const [switchClientesAprovados, setSwitchClientesAprovados] = useState(true);
  const [modalGridCheckpoints, setModalGridCheckpoints] = useState(false);
  const [modalGridLimiteComercial, setModalGridLimiteComercial] = useState(false);

  const buscarClientes = () => {
    let params = {
      filtro: {
        ...filtroDashboard,
        tipoCliente: switchClientesAprovados ? 'APROVADOS' : 'BLOQUEADOS'
      }
    };
    setIsPending(true);
    postListaClientesDashboard(params)
      .then(res => {
        let lstDados = res && res.data && res.data.lista_clientes ? res.data.lista_clientes : [];
        setQtdMaxPaginas(Math.ceil(parseInt(lstDados.length || 0) / qtdPorPagina));
        setListaClientes(lstDados);
        setPaginaSelecionada(1);
        setPaginacaoModel({ page: 0, pageSize: qtdPorPagina });
        if (res && res.data && res.data.media_totais)
          setDadosAdicionaisGrid({ dadosMedias: res.data.media_totais });
      })
      .catch(() => {
        setListaClientes([]);
      })
      .finally(() => {
        setIsPending(false);
        loadingDashFim('ClientesDashboard');
      });
  };

  const carregarMaisDados = () => {
    let pageTemp = paginaSelecionada + 1;
    if (pageTemp <= qtdMaxPaginas) {
      setPaginaSelecionada(pageTemp);
    }
  };

  const clickGrupoEconomico = params => {
    setModalGrid({ modal: 'grupo_economico', modalTitle: 'Grupo Empresarial', params });
  };

  const clickStatus = params => {
    setModalGrid({ modal: 'status', modalTitle: 'Status', params });
  };

  const clickParecer = params => {
    setModalGrid({ modal: 'parecer', modalTitle: 'Pareceres', params });
  };

  const clickCheckpoints = params => {
    setModalGridCheckpoints({
      cnpjCliente: params.cnpjCliente,
      nomeCliente: params.nomeCliente,
      modal: 'checkpoints',
      modalTitle: 'Checkpoints Negócios',
      ckeckpointsClientes: true,
      params
    });
  };

  const clickLimiteComercial = params => {
    setModalGridLimiteComercial({
      cnpjCliente: params.cnpjCliente,
      nomeCliente: params.nomeCliente,
      modal: 'limiteComercial',
      modalTitle: 'Limite Comercial',
      params
    });
  };

  const expandeRetraiFunc = () => {
    setExpandirRetrairGrid(expandirRetrairGrid ? false : true);
  };

  const configuraColunasGrid = () => {
    if (filtroDashboard && filtroDashboard.filtrar === false) return false;
    let configColumnsTemp = definicaoDeColunas({
      clickCheckpoints,
      clickLimiteComercial,
      clickGrupoEconomico,
      clickStatus,
      clickParecer,
      filtroDashboard,
      dadosUser,
      expandirRetrairGrid,
      expandeRetraiFunc,
      dadosAdicionaisGrid
    });

    let configGroupColumnsTemp = definicaoGrupoDeColunas({
      filtroDashboard,
      dadosUser,
      expandirRetrairGrid,
      dadosAdicionaisGrid
    });

    if (expandirRetrairGrid) {
      let novasColunas = [];
      let colunasProdutosRemover = [
        'limite_intercompany',
        'limite_giro_rapido',
        'limite_giro_parcelado',
        'limite_conta_escrow',
        'limite_escrow_simples',
        'risco_intercompany',
        'risco_giro_rapido',
        'risco_giro_parcelado',
        'risco_conta_escrow',
        'risco_escrow_simples',
        'disponivel_giro_rapido',
        'disponivel_intercompany',
        'disponivel_giro_parcelado',
        'disponivel_conta_escrow',
        'disponivel_escrow_simples'
      ];

      configColumnsTemp.map(function(coluna) {
        if (!colunasProdutosRemover.includes(coluna.field)) {
          novasColunas.push(coluna);
        }
        return coluna;
      });
      configColumnsTemp = novasColunas;
    }

    setDefinicoesGrid({ grupoColunas: configGroupColumnsTemp, colunas: configColumnsTemp });
  };

  useEffect(() => {
    configuraColunasGrid();
  }, [expandirRetrairGrid, dadosAdicionaisGrid]);

  useEffect(() => {
    if (paginaSelecionada === 1) return false;
    let pageSizeTemp = paginaSelecionada * qtdPorPagina;
    pageSizeTemp = pageSizeTemp < 100 ? pageSizeTemp : 100;
    setPaginacaoModel({ page: 0, pageSize: pageSizeTemp });
  }, [paginaSelecionada]);

  useEffect(() => {
    if (filtroDashboard && filtroDashboard.filtrar === false) return false;
    configuraColunasGrid();
    buscarClientes();
  }, [filtroDashboard, switchClientesAprovados]);

  useEffect(() => {
    if (listaClientes.length > 100 && paginacaoModel.pageSize === 100) {
      setEsconderFooter(false);
    } else {
      setEsconderFooter(true);
    }
  }, [paginacaoModel, listaClientes]);

  const SwitchClientesAprovadosComponente = () => (
    <Box className="float-right">
      <span
        className={`${
          !switchClientesAprovados ? 'texto-cor-cinza-escuro' : 'texto-cor-cinza-claro'
        } texto-weight-600`}
      >
        Bloqueados
      </span>
      <Switch
        checked={switchClientesAprovados}
        onChange={event => setSwitchClientesAprovados(event.target.checked)}
        disabled={isPending}
      />
      <span
        className={`${
          switchClientesAprovados ? 'texto-cor-cinza-escuro' : 'texto-cor-cinza-claro'
        } texto-weight-600`}
      >
        Aprovados
      </span>
    </Box>
  );

  return (
    <Box className="ajuste-spin-data-grid" sx={isPending ? { position: 'relative' } : {}}>
      {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
      {listaClientes !== false ? (
        <>
          {clienteAtual &&
            clienteAtual.id === 'todos' &&
            dadosUser &&
            parseInt(dadosUser.nivel) !== nivelUsuarioEnum.CLIENTE &&
            listaClientes.length <= 0 && <SwitchClientesAprovadosComponente />}
          {listaClientes.length > 0 ? (
            <>
              <DataGrid
                className="data-grid-clientes-dashboard data-grid-column-group data-grid-separator-header-hidden"
                columns={definicoesGrid.colunas}
                columnGroupingModel={definicoesGrid.grupoColunas}
                experimentalFeatures={{ columnGrouping: true }}
                rows={listaClientes}
                paginationModel={paginacaoModel}
                onPaginationModelChange={setPaginacaoModel}
                pageSizeOptions={[100]}
                getRowHeight={() => 'auto'}
                autoHeight
                disableColumnMenu
                disableRowSelectionOnClick
                sx={{ margin: '15px 0 30px 0' }}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                hideFooter={esconderFooter}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'grupoEconomico', sort: 'asc' }]
                  }
                }}
                slots={{
                  toolbar: () => (
                    <GridToolbarContainer>
                      <Grid container>
                        <Grid item xl={10} lg={9} md={8} sm={7} xs={6}>
                          <GridToolbarExport
                            printOptions={{ disableToolbarButton: true }}
                            csvOptions={{
                              fileName: 'Pipeline | Carteira | ' + moment().format('DDMMYYYY'),
                              delimiter: ';',
                              utf8WithBom: true
                            }}
                          />
                          <GridToolbarQuickFilter className="search-grid" />
                        </Grid>
                        <Grid item xl={2} lg={3} md={4} sm={5} xs={6}>
                          {clienteAtual &&
                            clienteAtual.id === 'todos' &&
                            dadosUser &&
                            parseInt(dadosUser.nivel) !== nivelUsuarioEnum.CLIENTE && (
                              <SwitchClientesAprovadosComponente />
                            )}
                        </Grid>
                      </Grid>
                    </GridToolbarContainer>
                  )
                }}
              />
              {esconderFooter && (
                <Grid container>
                  <Grid item xl={5} lg={5} md={4} sm={2} xs={0} />
                  <Grid item xl={2} lg={2} md={4} sm={5} xs={12}>
                    {paginaSelecionada < qtdMaxPaginas && (
                      <ButtonMain
                        type="button"
                        tipoBotao="transparente-com-bordas-principal"
                        onClick={() => carregarMaisDados()}
                        disabled={isPending}
                      >
                        <Box>
                          <Box className="display-inline-block">Carregar Mais</Box>
                          <Box className="icon-grid-clientes-qtd-paginas display-inline-block">
                            {String(
                              (qtdMaxPaginas < 10 ? qtdMaxPaginas : 10) - paginaSelecionada
                            ).padStart(2, '0')}
                          </Box>
                        </Box>
                      </ButtonMain>
                    )}
                  </Grid>
                  <Grid
                    item
                    xl={5}
                    lg={5}
                    md={4}
                    sm={5}
                    xs={12}
                    className="flex-box items-centralizados-direita pad-right-left-5"
                  >
                    <span className="font-size-1-rem texto-cor-cinza-escuro margin-right-7">
                      Clientes:
                    </span>
                    <span className="font-size-1-3-rem texto-cor-principal texto-weight-600">
                      {`${String(
                        paginacaoModel.pageSize < listaClientes.length
                          ? paginacaoModel.pageSize
                          : listaClientes.length
                      ).padStart(2, '0')}`}
                    </span>
                    <span className="font-size-1-rem texto-cor-principal margin-top-2">
                      {`/${String(listaClientes.length).padStart(2, '0')}`}
                    </span>
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <div className="error">
              <h1>Nenhum cliente encontrado</h1>
            </div>
          )}
        </>
      ) : (
        <div className="error">
          <h1>Ops!</h1>
          <p>Alguma coisa deu errado, tente novamente mais tarde?</p>
        </div>
      )}
      <ModalGridDashboard modalGrid={modalGrid} setModalGrid={setModalGrid} />
      {modalGridCheckpoints && (
        <ModalGridDashboardCheckpoints
          modalGridCheckpoints={modalGridCheckpoints}
          setModalGridCheckpoints={setModalGridCheckpoints}
          listaClientes={listaClientes}
          setListaClientes={setListaClientes}
        />
      )}
      {modalGridLimiteComercial && (
        <ModalGridDashboardLimiteComercial
          modalGridLimiteComercial={modalGridLimiteComercial}
          setModalGridLimiteComercial={setModalGridLimiteComercial}
          listaClientes={listaClientes}
          setListaClientes={setListaClientes}
          clienteAtual={clienteAtual}
          setAlerta={setAlerta}
        />
      )}
    </Box>
  );
};

export default ClientesDashboard;
