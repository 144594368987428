import { useEffect, useRef } from 'react';
import moment from 'moment';
import Cookies from 'universal-cookie';
import onboardingEtapasStatus from '../../components/Onboarding/shared/Enums/EnumsEtapasStatus';
import onboardingEtapasStatusConnect from '../../components/Onboarding/shared/Enums/EnumsEtapasStatusConnect';
import { getClientesStatus } from '../../api/clientes';
import { getListaPlataformasUsuario } from '../../api/dashboard';
import { getEscopoAmbiente } from '../../api/autenticacao';
import PlataformasEnums from '../Enums/PlataformasEnums';

// REF: https://blog.rocketseat.com.br/reactjs-autenticacao/
export const TOKEN_KEY = 'x-one7-token';
// export const TOKEN_EXPIRES = 'x-one7-token-expires';
export const TOKEN_KEY_REFRESH = 'x-one7-token-refresh';
// export const TOKEN_REFRESH_EXPIRES = 'x-one7-token-refresh-expires';
export const CLIENTES = 'x-one7-clientes';
export const CLIENTE_SELECIONADO = 'x-one7-cliente';
export const CLIENTE_STATUS = 'x-one7-cliente-status';
export const USUARIO_LOGADO = 'x-one7-usuario';
export const TELAS = 'x-one7-telas';
export const EMAIL_VALIDA_TOKEN = 'email-valida-token';
export const TELA_REQUISITORA_TOKEN = 'tela-requisitora-token';
export const VERTICAL_ESCOLHIDA = 'x-one7-vertical-escolhida';
export const PLATAFORMA_ESCOLHIDA = 'x-one7-plataforma-escolhida';
export const GERENTE_ESCOLHIDO = 'x-one7-gerente-escolhido';
export const UNIDADE_NEGOCIO_ESCOLHIDO = 'x-one7-unidade-negocio-escolhido';
export const LISTA_VERTICAIS = 'x-one7-lista-verticais';
export const LISTA_PLATAFORMAS = 'x-one7-lista-plataformas';
export const LISTA_USUARIOS_PLATAFORMAS = 'x-one7-lista-usuarios-plataformas';
export const LISTA_GERENTES_PLATAFORMAS = 'x-one7-lista-gerentes-plataformas';
export const LISTA_UNIDADES_NEGOCIOS = 'x-one7-lista-unidades-negocios';
export const MOSTRA_MODAL_DASHBOARD = 'x-one7-mostra-modal-dashboard';
export const MOSTRA_MODAL_NOVIDADES = 'x-one7-mostra-modal-novidades';
export const AUTH_COOKIE = 'x-one7-auth-cookie';
export const ESCOPO_COMPONENTES = 'x-one7-escopo-componentes';
export const TELA_DEFAULT = 'x-one7-tela-default';
export const REDIRECIONAMENTO_REALIZADO = 'x-one7-redirecionamento-realizado';
export const TELASHOME = [
  '/',
  '/home',
  '/dashboard',
  '/borderos',
  '/new',
  '/instrucoes',
  '/relatorios',
  '/retornos',
  '/documentos-cliente',
  // '/tutoriais',
  '/nova-entrada',
  '/jornada-credito'
];
export const TAMANHO_MAXIMO_UPLOAD = 120000000;

var dominioURL = 'one7';
if (window && window.location.hostname !== 'localhost') {
  const hostnameParts = window.location.hostname.split('.');
  dominioURL = hostnameParts[hostnameParts.length - 2];
}
export const CONFIG_AMBIENTE = `x-${dominioURL}-configuracoes-ambiente`;

let telaAtual = null;

export const setTelaAtual = NovaTela => {
  telaAtual = NovaTela;
};

export const getTelaAtual = () => {
  return telaAtual;
};

export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);
export const getTokenRefresh = () => sessionStorage.getItem(TOKEN_KEY_REFRESH);

export const setToken = token => {
  sessionStorage.setItem(TOKEN_KEY, token);
  // const expireTimestamp = moment()
  //   .add(expires, 'seconds')
  //   .valueOf();
  // sessionStorage.setItem(TOKEN_EXPIRES, expireTimestamp);
};

export const setTokenRefresh = token => {
  sessionStorage.setItem(TOKEN_KEY_REFRESH, token);
  // const expireTimestamp = moment()
  //   .add(expires, 'seconds')
  //   .valueOf();
  // sessionStorage.setItem(TOKEN_REFRESH_EXPIRES, expireTimestamp);
};

export const removeToken = () => {
  sessionStorage.removeItem(TOKEN_KEY);
  // sessionStorage.removeItem(TOKEN_EXPIRES);
};

export const removeTokenRefresh = () => {
  sessionStorage.removeItem(TOKEN_KEY_REFRESH);
  // sessionStorage.removeItem(TOKEN_REFRESH_EXPIRES);
};

export const removeClienteAtual = () => {
  sessionStorage.removeItem(CLIENTE_SELECIONADO);
};

// export const tokenIsExpired = () => {
//   const tokenTimestamp = +sessionStorage.getItem(TOKEN_EXPIRES);
//   const currentTimestamp = +moment().valueOf();
//   return currentTimestamp > tokenTimestamp;
// };

// export const tokenRefreshIsExpired = () => {
//   const tokenTimestamp = +sessionStorage.getItem(TOKEN_REFRESH_EXPIRES);

//   const currentTimestamp = +moment().valueOf();
//   return currentTimestamp > tokenTimestamp;
// };

export const getClienteAtual = () => JSON.parse(sessionStorage.getItem(CLIENTE_SELECIONADO));

export const getClienteStatus = () => JSON.parse(sessionStorage.getItem(CLIENTE_STATUS));

export const setClienteStatus = cliStatus => {
  sessionStorage.setItem(CLIENTE_STATUS, JSON.stringify(cliStatus));
};

export const setClienteAtual = cliente => {
  if (cliente) {
    sessionStorage.setItem(CLIENTE_SELECIONADO, JSON.stringify(cliente));
  }
};

export const setClientes = clientes => {
  if (clientes) {
    sessionStorage.setItem(CLIENTES, JSON.stringify(clientes));
  }
};

export const getClientes = () => {
  let clientes = JSON.parse(sessionStorage.getItem(CLIENTES));
  if (clientes && typeof clientes === 'object' && clientes.length > 0) {
    return clientes;
  } else {
    return [];
  }
};

export const setTelas = (onBoarding = false, home = false) => {
  sessionStorage.setItem(TELAS, JSON.stringify({ etapaOnboarding: onBoarding, home: home }));
};

export const getTelas = () => JSON.parse(sessionStorage.getItem(TELAS));

export const setEscopoComponentes = escopo =>
  sessionStorage.setItem(ESCOPO_COMPONENTES, btoa(JSON.stringify(escopo)));

export const getEscopoComponentes = () => {
  var escopoAcesso = sessionStorage.getItem(ESCOPO_COMPONENTES);
  if (escopoAcesso) {
    escopoAcesso = atob(escopoAcesso);
    try {
      return JSON.parse(escopoAcesso);
    } catch (er) {
      return false;
    }
  } else return false;
};

export const setConfigAmbiente = escopo => {
  if (escopo) {
    escopo.dataEscopo = parseInt(moment().format('YYMMDD'), 10);
    localStorage.setItem(CONFIG_AMBIENTE, btoa(JSON.stringify(escopo)));
    return escopo;
  } else return false;
};

export const getConfigAmbiente = () => {
  var configAmbiente = localStorage.getItem(CONFIG_AMBIENTE);
  if (configAmbiente) {
    configAmbiente = atob(configAmbiente);
    try {
      return JSON.parse(configAmbiente);
    } catch (er) {
      return false;
    }
  } else {
    window.location.href = window.location.href;
    return false;
  }
};

export const setTelaDefault = telaDefault =>
  sessionStorage.setItem(TELA_DEFAULT, btoa(JSON.stringify(telaDefault)));

export const getTelaDefault = () => {
  var telaDefault = sessionStorage.getItem(TELA_DEFAULT);
  if (telaDefault) {
    telaDefault = atob(telaDefault);
    try {
      return JSON.parse(telaDefault);
    } catch (er) {
      return false;
    }
  } else return false;
};

export const setRedirecionamentoRealizado = () => {
  sessionStorage.setItem(REDIRECIONAMENTO_REALIZADO, true);
};

export const getRedirecionamentoRealizado = () => {
  return sessionStorage.getItem(REDIRECIONAMENTO_REALIZADO);
};

export const getEmailValidaToken = () => {
  if (sessionStorage.getItem(EMAIL_VALIDA_TOKEN)) {
    return sessionStorage.getItem(EMAIL_VALIDA_TOKEN);
  } else {
    return false;
  }
};

export const setEmailValidaToken = emailValidaToken => {
  sessionStorage.setItem(EMAIL_VALIDA_TOKEN, emailValidaToken);
};

export const removeEmailValidaToken = () => {
  if (sessionStorage.getItem(EMAIL_VALIDA_TOKEN)) {
    sessionStorage.removeItem(EMAIL_VALIDA_TOKEN);
  }
};

export const getTelaRequisitoraToken = () => {
  if (sessionStorage.getItem(EMAIL_VALIDA_TOKEN)) {
    return sessionStorage.getItem(TELA_REQUISITORA_TOKEN);
  } else {
    return false;
  }
};

export const setTelaRequisitoraToken = telaRequisitoraToken => {
  sessionStorage.setItem(TELA_REQUISITORA_TOKEN, telaRequisitoraToken);
};

export const removeTelaRequisitoraToken = () => {
  if (sessionStorage.getItem(TELA_REQUISITORA_TOKEN)) {
    sessionStorage.removeItem(TELA_REQUISITORA_TOKEN);
  }
};

export const etapaTelaOnborading = () => {
  let telasPermitidas = getTelas();
  if (telasPermitidas && telasPermitidas.etapaOnboarding === true && telasPermitidas.home === false) {
    return true;
  }
  return false;
};

export const etapaTelaHome = () => {
  let telasPermitidas = getTelas();
  if (telasPermitidas && telasPermitidas.etapaOnboarding === false && telasPermitidas.home === true) {
    return true;
  }
  return false;
};

export const setDadosUsuario = usuario => {
  usuario.email_escondido = escondeEmail(usuario.email || '');
  sessionStorage.setItem(USUARIO_LOGADO, JSON.stringify(usuario));
};

export const getDadosUsuario = () => JSON.parse(sessionStorage.getItem(USUARIO_LOGADO));

export const verificaClienteOnboarding = cliente => {
  let clienteCnpj = cliente && cliente.cnpj ? cliente.cnpj : 0;

  return new Promise((resolve, reject) => {
    if (clienteCnpj === 0 || clienteCnpj === 'todos') return resolve(false);
    return getClientesStatus(clienteCnpj)
      .then(resCliStatus => {
        cliente.empresa_onboarding = resCliStatus.data.empresa_onboarding;
        cliente.ppc_status = resCliStatus.data.ppc_status;
        setClienteStatus(cliente);

        // VERIFICA SE A PROPOSTRA DELE VEM DE FORA DA JORNADA E JOGA PRA HOME
        if (
          cliente &&
          cliente.ppc_status &&
          (typeof cliente.ppc_status.tipo_origem_proposta === 'undefined' ||
            cliente.ppc_status.tipo_origem_proposta === 'interno' ||
            cliente.ppc_status.tipo_origem_proposta === 'one7_mais_credito')
        ) {
          setTelas(false, true);
          return resolve({ home: true, cliente });
        }

        if (
          cliente.ppc_status === undefined ||
          cliente.ppc_status === null ||
          cliente.ppc_status.sigla === undefined
        ) {
          if (cliente.empresa_onboarding !== undefined && cliente.empresa_onboarding === true) {
            setTelas(true, false);
            return resolve({
              jornada_onboarding: true,
              empresa_onboarding: true,
              cliente
            });
          } else {
            setTelas(false, true);
            return resolve({ home: true, cliente });
          }
        } else if (
          (cliente.ppc_status.tipo_origem_proposta === 'connect' ||
            cliente.ppc_status.tipo_origem_proposta === 'indicantes') &&
          onboardingEtapasStatusConnect[cliente.ppc_status.sigla] !== undefined
        ) {
          setTelas(true, false);
          return resolve({
            jornada_onboarding: true,
            etapaOnboarding: onboardingEtapasStatusConnect[cliente.ppc_status.sigla],
            tipo_jornada: 'connect',
            cliente
          });
        } else if (
          cliente.ppc_status.tipo_origem_proposta === 'digital' &&
          onboardingEtapasStatus[cliente.ppc_status.sigla] !== undefined
        ) {
          setTelas(true, false);
          return resolve({
            jornada_onboarding: true,
            etapaOnboarding: onboardingEtapasStatus[cliente.ppc_status.sigla],
            cliente
          });
        } else {
          setTelas(false, true);
          return resolve({ home: true, cliente });
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const escondeEmail = email => {
  if (email !== undefined && email !== '') {
    let index = email.indexOf('@');
    if (index >= 0) {
      let stringInicial = email.substring(0, index);
      let stringFinal = email.substring(index);
      let manter = stringInicial.slice(-3);
      let remover = stringInicial.replace(manter, '');
      let stringEscondida = manter.padStart(remover.length + 3, '*');
      return stringEscondida + stringFinal;
    }
  }
  return '*****@*****.com';
};

export const verificaSeStringExiste = (
  string = '',
  parametro = '',
  ignorar = '',
  caseSensitive = true
) => {
  if (string === '' || parametro === '') return false;
  if (string === ignorar) return true;

  string = caseSensitive ? string : string.toLowerCase();

  if (Array.isArray(parametro)) {
    let result = false;
    parametro.map(str => {
      str = caseSensitive ? str : str.toLowerCase();
      if (string.includes(str) && str !== ignorar) {
        result = true;
      }
      return str;
    });
    return result;
  } else {
    parametro = caseSensitive ? parametro : parametro.toLowerCase();
    return string.includes(parametro);
  }
};

export const downloadFileToken = (urlArquivo, nomeArquivo = false, setLoad = false, setErro = false) => {
  if (!urlArquivo) {
    return 'Falha para obter arquivo, informações ausentes.';
  }

  if (nomeArquivo === false && urlArquivo.includes('/')) {
    let nomeUrl = urlArquivo.split('/');
    nomeArquivo = nomeUrl[nomeUrl.length - 1];
  }
  var fileUrl;
  try {
    var objUrl = new URL(urlArquivo);
    fileUrl = `${process.env.REACT_APP_API_UPLOAD_BASE_URL}${objUrl.pathname}`;
  } catch (er) {
    fileUrl = `${process.env.REACT_APP_API_UPLOAD_BASE_URL}${process.env.REACT_APP_API_BASE_PATH}/${urlArquivo}`;
  }

  if (typeof setLoad === 'function') setLoad(true);
  fetch(fileUrl, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  })
    .then(response => {
      if (response.status !== 200) {
        return false;
      }
      return response.blob();
    })
    .then(blob => {
      if (blob) {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nomeArquivo);
        document.body.appendChild(link);
        link.click();
        link.remove();
        if (typeof setLoad === 'function') setLoad(false);
      } else {
        if (typeof setLoad === 'function') setLoad(false);
        if (typeof setErro === 'function')
          setErro('Falha para obter arquivo, favor contatar o nosso suporte.');
        return 'Falha para obter arquivo, favor contatar o nosso suporte.';
      }
    })
    .catch(() => {
      if (typeof setLoad === 'function') setLoad(false);
      if (typeof setErro === 'function')
        setErro('Falha ao obter arquivo, favor contatar o nosso suporte.');
      return 'Falha ao obter arquivo, favor contatar o nosso suporte.';
    });

  return true;
};

export const exibeValorResumido = (num, digits = 1) => {
  num = parseInt(num);
  const modelo = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: ' M' },
    { value: 1e6, symbol: ' MM' },
    { value: 1e9, symbol: ' B' },
    { value: 1e12, symbol: ' T' },
    { value: 1e15, symbol: ' qua' },
    { value: 1e18, symbol: ' qui' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = modelo
    .slice()
    .reverse()
    .find(function(item) {
      return num >= item.value;
    });
  let valor = item ? num / item.value : 0;
  let factor = Math.pow(10, digits);
  valor = Math.floor(valor * factor) / factor;
  return valor ? String(valor).replace(rx, '$1') + item.symbol : '0';
};

export const setCodVerticalEscolhida = codVertical => {
  sessionStorage.setItem(VERTICAL_ESCOLHIDA, JSON.stringify(codVertical));
};

export const getCodVerticalEscolhida = () => {
  return JSON.parse(sessionStorage.getItem(VERTICAL_ESCOLHIDA));
};

export const setCodPlataformaEscolhida = codPlataforma => {
  sessionStorage.setItem(PLATAFORMA_ESCOLHIDA, JSON.stringify(codPlataforma));
};

export const getCodPlataformaEscolhida = () => {
  return JSON.parse(sessionStorage.getItem(PLATAFORMA_ESCOLHIDA));
};

export const setCodGerenteEscolhido = codGerente => {
  sessionStorage.setItem(GERENTE_ESCOLHIDO, JSON.stringify(codGerente));
};

export const getCodGerenteEscolhido = () => {
  return JSON.parse(sessionStorage.getItem(GERENTE_ESCOLHIDO));
};

export const setCodUnidadeEscolhido = codUnidadeNegocio => {
  sessionStorage.setItem(UNIDADE_NEGOCIO_ESCOLHIDO, JSON.stringify(codUnidadeNegocio));
};

export const getCodUnidadeEscolhido = () => {
  return JSON.parse(sessionStorage.getItem(UNIDADE_NEGOCIO_ESCOLHIDO));
};

export const setListaVerticais = lstVerticais => {
  sessionStorage.setItem(LISTA_VERTICAIS, JSON.stringify(lstVerticais));
};

export const getListaVerticais = () => {
  return JSON.parse(sessionStorage.getItem(LISTA_VERTICAIS));
};

export const setListaPlataformas = lstPlataformas => {
  sessionStorage.setItem(LISTA_PLATAFORMAS, JSON.stringify(lstPlataformas));
};

export const getListaPlataformas = () => {
  return JSON.parse(sessionStorage.getItem(LISTA_PLATAFORMAS));
};

export const setListaUsuarioPlataformas = lstusuarioPlataformas => {
  sessionStorage.setItem(LISTA_USUARIOS_PLATAFORMAS, JSON.stringify(lstusuarioPlataformas));
};

export const getListaUsuarioPlataformas = () => {
  return JSON.parse(sessionStorage.getItem(LISTA_USUARIOS_PLATAFORMAS));
};

export const setListaGerentesPlataformas = lstGerentesPlataformas => {
  sessionStorage.setItem(LISTA_GERENTES_PLATAFORMAS, JSON.stringify(lstGerentesPlataformas));
};

export const getListaGerentesPlataformas = () => {
  return JSON.parse(sessionStorage.getItem(LISTA_GERENTES_PLATAFORMAS));
};

export const setListaUnidadesNegocios = lstUnidadesNegocios => {
  sessionStorage.setItem(LISTA_UNIDADES_NEGOCIOS, JSON.stringify(lstUnidadesNegocios));
};

export const getListaUnidadesNegocios = () => {
  return JSON.parse(sessionStorage.getItem(LISTA_UNIDADES_NEGOCIOS));
};

export const setMostraModalDashboard = modal => {
  sessionStorage.setItem(MOSTRA_MODAL_DASHBOARD, JSON.stringify(modal));
};

export const getMostraModalDashboard = () => {
  return JSON.parse(sessionStorage.getItem(MOSTRA_MODAL_DASHBOARD));
};

export const getMostraModalNovidades = () => {
  return JSON.parse(sessionStorage.getItem(MOSTRA_MODAL_NOVIDADES));
};

export const setMostraModalNovidades = modal => {
  sessionStorage.setItem(MOSTRA_MODAL_NOVIDADES, JSON.stringify(modal));
};

export const verificaMostraFiltroPlataforma = () => {
  let user = getDadosUsuario();
  if (user && user.nivel !== undefined) {
    // existe usuario logado
  } else {
    return false;
  }

  let clienteAtual = getClienteAtual();
  if (!clienteAtual && !clienteAtual.codCliente) {
    return false;
  }

  if (parseInt(user.nivel) !== 7 && clienteAtual.codCliente === 'todos') {
    return true;
  }

  return false;
};

export const exibeValorLocalizado = (valor, minFractDig = 0, maxFractDig = 0) => {
  return valor.toLocaleString('pt-br', {
    minimumFractionDigits: minFractDig,
    maximumFractionDigits: maxFractDig
  });
};

export const retornaValorComMascara = (val, mask) => {
  if (typeof val === 'undefined' || val === null || val === '') {
    return '';
  }
  if (typeof mask === 'undefined' || mask === null || mask === '') {
    return val;
  }
  var maskared = '';
  var k = 0;

  for (var i = 0; i <= mask.length - 1; i++) {
    if (mask[i] === '#') {
      if (typeof val[k] !== 'undefined') {
        maskared += val[k++];
      }
    } else {
      if (typeof mask[i] !== 'undefined') {
        maskared += mask[i];
      }
    }
  }

  return maskared;
};

export const mascararTelefone = telefone => {
  if (telefone === null && telefone === undefined && telefone === '') return '';
  return retornaValorComMascara(
    telefone,
    telefone.length === 8
      ? '####-####'
      : telefone.length === 9
      ? '#####-####'
      : telefone.length === 10
      ? '(##) ####-####'
      : '(##) #####-####'
  );
};

export const mascararCpfCnpj = cpfCnpj => {
  return retornaValorComMascara(cpfCnpj, cpfCnpj.length <= 11 ? '###.###.###-##' : '##.###.###/####-##');
};

export const listarPlataformaEListarGerentes = ({
  setLstPlataformas = false,
  setPlataforma = false,
  setLstPlataformasFiltradas = false,
  setListaFiltradaGerentes = false,
  setListaFiltradaUnidadeNegocio = false,
  setGerenteEscolhido = false,
  setUnidadeNegocioEscolhido = false,
  funcaoSucesso = false,
  setPending = false,
  setPlatafUser = false,
  setLstVerticais = false,
  setVertical = false
  // setValorPadrao = 24
}) => {
  if (getListaPlataformas()) {
    let lstVerticais = getListaVerticais();
    let lstPlat = getListaPlataformas();
    let lstUsuPlat = getListaUsuarioPlataformas();

    let vertical = verificaRegrasSelecionaVertical(lstUsuPlat, lstVerticais);

    setVertical(vertical);
    setCodVerticalEscolhida(vertical);
    setLstVerticais(lstVerticais);

    let lstPlatFiltradas = [...lstPlat];
    if (setLstPlataformasFiltradas) {
      lstPlatFiltradas = [...lstPlat].filter(
        plataf => parseInt(plataf.codVertical) === parseInt(vertical)
      );
      setLstPlataformasFiltradas(lstPlatFiltradas);
    }
    // setValorPadrao(24);
    let plataforma = verificaRegrasSelecionaPlataforma(lstUsuPlat, lstPlatFiltradas);

    setPlataforma(plataforma);
    setCodPlataformaEscolhida(plataforma);
    setLstPlataformas(lstPlat);

    if (lstUsuPlat && setPlatafUser !== false) {
      setPlatafUser(lstUsuPlat);
    }

    let unidadeNegocioEscolhido = null;
    if (Array.isArray(getListaUnidadesNegocios()) && getListaUnidadesNegocios().length > 0) {
      let lstFiltradaUnidadeNegocioPlataforma = listaFiltradaUnidadeNegocioPlataforma(plataforma);
      if (lstFiltradaUnidadeNegocioPlataforma.length > 0) {
        lstFiltradaUnidadeNegocioPlataforma = normalizaListaUnidadesNegociosPlataforma(
          lstFiltradaUnidadeNegocioPlataforma
        );
        setListaFiltradaUnidadeNegocio(lstFiltradaUnidadeNegocioPlataforma);
        unidadeNegocioEscolhido = verificaRegrasSelecionaUnidadeNegocio(
          lstUsuPlat,
          lstFiltradaUnidadeNegocioPlataforma
        );
        if (
          getCodUnidadeEscolhido() &&
          lstFiltradaUnidadeNegocioPlataforma &&
          lstFiltradaUnidadeNegocioPlataforma.filter(e => e.value === getCodUnidadeEscolhido()).length >
            0
        ) {
          unidadeNegocioEscolhido = getCodUnidadeEscolhido();
        }
        setUnidadeNegocioEscolhido(unidadeNegocioEscolhido);
        setCodUnidadeEscolhido(unidadeNegocioEscolhido);
      }
    }

    let lstFiltradaGerentes = listaFiltradaGerentesPlataforma(plataforma, unidadeNegocioEscolhido);

    lstFiltradaGerentes = normalizaListaGerentesPlataforma(lstFiltradaGerentes);

    setListaFiltradaGerentes(lstFiltradaGerentes);
    let gerenteEscolhido = verificaRegrasSelecionaGerente(lstUsuPlat, lstFiltradaGerentes);

    if (getCodGerenteEscolhido() && lstFiltradaGerentes) {
      if (lstFiltradaGerentes.filter(e => e.value === getCodGerenteEscolhido()).length > 0) {
        gerenteEscolhido = getCodGerenteEscolhido();
      } else {
        gerenteEscolhido = lstFiltradaGerentes[0].value;
        setCodGerenteEscolhido(gerenteEscolhido);
      }
    }

    if (plataforma.includes(PlataformasEnums.PLATAFORMA_TOAZUL)) {
      gerenteEscolhido = 'todos';
      setCodGerenteEscolhido(gerenteEscolhido);
    }

    setGerenteEscolhido(gerenteEscolhido);
    if (funcaoSucesso) {
      funcaoSucesso(vertical, plataforma, gerenteEscolhido, unidadeNegocioEscolhido);
    }
    if (setPending) setPending(false);
  } else {
    getListaPlataformasUsuario()
      .then(res => {
        var lstVerticais = res.data && res.data.lstVerticais ? res.data.lstVerticais : [],
          lstPlataformas = res.data && res.data.lstPlataformas ? res.data.lstPlataformas : [],
          usuarioPlataforma = res.data && res.data.usuarioPlataformas ? res.data.usuarioPlataformas : {},
          lstGerentesplataforma =
            res.data && res.data.lstGerentesplataforma ? res.data.lstGerentesplataforma : {},
          lstUnidadesNegocios =
            res.data && res.data.lstUnidadesNegocios ? res.data.lstUnidadesNegocios : {},
          user = getDadosUsuario();

        if (!Array.isArray(lstVerticais) || lstVerticais.length === 0) return false;
        if (!Array.isArray(lstPlataformas) || lstPlataformas.length === 0) return false;
        if (!Array.isArray(lstGerentesplataforma) || lstGerentesplataforma.length === 0) return false;

        if (usuarioPlataforma && setPlatafUser !== false) {
          setPlatafUser(usuarioPlataforma);
        }

        if (
          user &&
          (user.nivel === 5 || user.nivel === 6) &&
          usuarioPlataforma &&
          usuarioPlataforma.brokerPlataf &&
          usuarioPlataforma.brokerPlataf.codPlataforma
        ) {
          lstPlataformas = lstPlataformas.filter(
            plataforma => plataforma.CODAGENCIA === usuarioPlataforma.brokerPlataf.codPlataforma
          );
        }

        lstVerticais = lstVerticais.map(dados => {
          return {
            value: dados.CODVERTICALNEGOCIO,
            label: dados.NOME
          };
        });

        lstPlataformas = lstPlataformas.map(dados => {
          return {
            value: dados.CODAGENCIA,
            label: dados.NOME,
            valueMD5: dados.CODAGENCIA_MD5,
            tipoOnboarding: dados.TIPOONBOARDING,
            codVertical: dados.CODVERTICALNEGOCIO
          };
        });

        let vertical = verificaRegrasSelecionaVertical(usuarioPlataforma, lstVerticais);

        setLstVerticais(lstVerticais);
        setListaVerticais(lstVerticais);
        setVertical(vertical);
        setCodVerticalEscolhida(vertical);

        var plataformasFiltradas = [...lstPlataformas];
        if (setLstPlataformasFiltradas) {
          plataformasFiltradas = [...lstPlataformas].filter(
            plataf => parseInt(plataf.codVertical) === parseInt(vertical)
          );
          setLstPlataformasFiltradas(plataformasFiltradas);
        }
        let plataforma = verificaRegrasSelecionaPlataforma(usuarioPlataforma, plataformasFiltradas);
        setLstPlataformas(lstPlataformas);
        setListaPlataformas(lstPlataformas);
        setPlataforma(plataforma);
        setCodPlataformaEscolhida(plataforma);

        setListaUsuarioPlataformas(usuarioPlataforma);
        setListaGerentesPlataformas(lstGerentesplataforma);
        let unidadeNegocioEscolhido = null;

        if (Array.isArray(lstUnidadesNegocios) && lstUnidadesNegocios.length > 0) {
          setListaUnidadesNegocios(lstUnidadesNegocios);

          let lstFiltradaUnidadeNegocioPlataforma = listaFiltradaUnidadeNegocioPlataforma(plataforma);

          if (lstFiltradaUnidadeNegocioPlataforma.length > 0) {
            lstFiltradaUnidadeNegocioPlataforma = normalizaListaUnidadesNegociosPlataforma(
              lstFiltradaUnidadeNegocioPlataforma
            );
            setListaFiltradaUnidadeNegocio(lstFiltradaUnidadeNegocioPlataforma);
            unidadeNegocioEscolhido = verificaRegrasSelecionaUnidadeNegocio(
              usuarioPlataforma,
              lstFiltradaUnidadeNegocioPlataforma
            );
            if (
              getCodUnidadeEscolhido() &&
              lstFiltradaUnidadeNegocioPlataforma &&
              lstFiltradaUnidadeNegocioPlataforma.filter(e => e.value === getCodUnidadeEscolhido())
                .length > 0
            ) {
              unidadeNegocioEscolhido = getCodUnidadeEscolhido();
            }
            setUnidadeNegocioEscolhido(unidadeNegocioEscolhido);
            setCodUnidadeEscolhido(unidadeNegocioEscolhido);
          }
        }

        let lstFiltradaGerentes = listaFiltradaGerentesPlataforma(
          plataforma,
          unidadeNegocioEscolhido,
          lstGerentesplataforma,
          usuarioPlataforma
        );

        lstFiltradaGerentes = normalizaListaGerentesPlataforma(lstFiltradaGerentes);
        setListaFiltradaGerentes(lstFiltradaGerentes);

        let gerenteEscolhido = verificaRegrasSelecionaGerente(usuarioPlataforma, lstFiltradaGerentes);

        if (
          getCodGerenteEscolhido() &&
          lstFiltradaGerentes &&
          lstFiltradaGerentes.filter(e => e.value === getCodGerenteEscolhido()).length > 0
        ) {
          gerenteEscolhido = getCodGerenteEscolhido();
        }

        if (plataforma === PlataformasEnums.PLATAFORMA_TOAZUL) {
          gerenteEscolhido = 'todos';
        }
        setGerenteEscolhido(gerenteEscolhido);
        setCodGerenteEscolhido(gerenteEscolhido);

        if (funcaoSucesso) {
          funcaoSucesso(plataforma, gerenteEscolhido, unidadeNegocioEscolhido);
        }
      })
      .finally(() => {
        if (setPending) setPending(false);
      });
  }
};

export const listaFiltradaUnidadeNegocioPlataforma = (
  plataforma,
  lstUnidadesNegocios = false,
  lstUsuarioPlataforma = false
) => {
  if (!lstUnidadesNegocios) {
    lstUnidadesNegocios = getListaUnidadesNegocios();
  }

  if (!lstUsuarioPlataforma) {
    lstUsuarioPlataforma = getListaUsuarioPlataformas();
  }

  let user = getDadosUsuario();
  let gerenteUnico = false;

  if (
    user &&
    user.nivel === 5 &&
    lstUsuarioPlataforma &&
    lstUsuarioPlataforma.brokerPlataf &&
    lstUsuarioPlataforma.brokerPlataf.codBroker
  ) {
    gerenteUnico = lstUsuarioPlataforma.brokerPlataf.codUnidadeNegocio;
  }

  var unidNeg = [];
  if (gerenteUnico && lstUnidadesNegocios) {
    unidNeg = lstUnidadesNegocios.filter(
      unidadeNegocio => parseInt(unidadeNegocio.codUnidadeNegocio) === gerenteUnico
    );
    return unidNeg;
  }

  var unidadeNegocio = [];
  var unique = [];
  var uniqueTodasUnidadeNegocio = [];

  if (plataforma.length > 0) {
    if (lstUnidadesNegocios) {
      lstUnidadesNegocios.forEach(unidade => {
        if (plataforma.includes(unidade.codAgencia)) {
          unidadeNegocio.push(unidade);
        }
      });

      unique = unidadeNegocio.filter(unid => unid.codUnidadeNegocio !== 'todos');
      uniqueTodasUnidadeNegocio = unidadeNegocio.filter(unid => unid.codUnidadeNegocio === 'todos');
      unidadeNegocio = unique.concat(uniqueTodasUnidadeNegocio[0]);
    }
  } else if (lstUnidadesNegocios) {
    unidadeNegocio = lstUnidadesNegocios.filter(unidadeNegoc => unidadeNegoc.codAgencia === plataforma);
  }

  return unidadeNegocio;
};

export const listaFiltradaGerentesPlataforma = (
  plataforma,
  codUnidadeNegocio = false,
  lstGerentesplataforma = false,
  lstUsuarioPlataforma = false
) => {
  if (!lstGerentesplataforma) {
    lstGerentesplataforma = getListaGerentesPlataformas();
  }

  if (!lstUsuarioPlataforma) {
    lstUsuarioPlataforma = getListaUsuarioPlataformas();
  }

  let user = getDadosUsuario();
  let gerenteUnico = false;

  if (
    user &&
    user.nivel === 5 &&
    lstUsuarioPlataforma &&
    lstUsuarioPlataforma.brokerPlataf &&
    lstUsuarioPlataforma.brokerPlataf.codBroker
  ) {
    gerenteUnico = lstUsuarioPlataforma.brokerPlataf.codBroker;
  }
  if (gerenteUnico) {
    return lstGerentesplataforma.filter(gerente => gerente.codBroker === gerenteUnico);
  }

  let gerente = [];

  if (plataforma.length > 0) {
    if (lstGerentesplataforma) {
      // gerente = lstGerentesplataforma.filter(gerentePlataforma =>
      //   plataforma.some(platf =>
      //     Object.values(gerentePlataforma.codAgencia).every(
      //       (value, index) => value === Object.values(platf.value)[index]
      //     )
      //   )
      // );
      lstGerentesplataforma.forEach(ger => {
        if (plataforma.includes(ger.codAgencia)) {
          gerente.push(ger);
        }
      });

      const uniqueGerente = gerente.filter(ger => ger.nome !== 'TODOS OS GERENTES');

      const uniqueTodosGerenteNegocio = gerente.filter(ger => ger.nome === 'TODOS OS GERENTES');

      gerente = uniqueGerente.concat(uniqueTodosGerenteNegocio[0]);
    }
  } else if (lstGerentesplataforma) {
    gerente = lstGerentesplataforma.filter(gerente => gerente.codAgencia === plataforma);
  }

  let gerenteUnidadeNegocio = [];
  if (codUnidadeNegocio) {
    gerenteUnidadeNegocio = gerente.filter(
      gerente =>
        (gerente.codUnidadeNegocio && gerente.codUnidadeNegocio === codUnidadeNegocio) ||
        gerente.codBroker === 'todos' ||
        (codUnidadeNegocio === 'todos' && (gerente.codUnidadeNegocio !== null || gerente.gestor === 'S'))
    );
  }

  return gerenteUnidadeNegocio.length > 0 ? gerenteUnidadeNegocio : gerente;
};

export const normalizaListaGerentesPlataforma = (lstGerentes = []) => {
  let lstNormalizadadaGerentes = lstGerentes.map(dados => {
    return { value: dados.codBroker, label: dados.nome, valueMD5: dados.codBrokerMD5 };
  });
  return lstNormalizadadaGerentes ? lstNormalizadadaGerentes : [];
};

export const normalizaListaUnidadesNegociosPlataforma = (lstUnidadesNegocio = []) => {
  let lstNormalizadaUnidadeNegocio =
    lstUnidadesNegocio &&
    lstUnidadesNegocio.length > 1 &&
    lstUnidadesNegocio.map(dados => {
      return { value: dados.codUnidadeNegocio, label: dados.nome, valueMD5: dados.codUnidadeNegocioMD5 };
    });
  return lstNormalizadaUnidadeNegocio ? lstNormalizadaUnidadeNegocio : [];
};

export const verificaRegrasSelecionaVertical = (usuarioPlataforma, lstVerticais) => {
  let vertical = 0;
  if (getCodVerticalEscolhida()) {
    vertical = getCodVerticalEscolhida();
  } else if (usuarioPlataforma.brokerPlataf && usuarioPlataforma.brokerPlataf.codVertical) {
    vertical = usuarioPlataforma.brokerPlataf.codVertical;
  } else if (usuarioPlataforma.procuradorPlataf && usuarioPlataforma.procuradorPlataf.codVertical) {
    vertical = usuarioPlataforma.procuradorPlataf.codVertical;
  }
  if (vertical !== 0) {
    let existeVertical = false;
    lstVerticais = lstVerticais.map(dados => {
      if (dados.value === vertical) existeVertical = true;
      return dados;
    });
    if (!existeVertical && lstVerticais.length > 0) {
      vertical = lstVerticais[0].value;
    }
  } else if (lstVerticais && lstVerticais.length > 0) {
    vertical = lstVerticais[0].value;
  }
  return vertical;
};

export const verificaRegrasSelecionaPlataforma = (usuarioPlataforma, lstPlataformas) => {
  let plataforma = []; //0;

  if (getCodPlataformaEscolhida()) {
    plataforma = getCodPlataformaEscolhida();
  } else if (usuarioPlataforma.brokerPlataf) {
    plataforma = [usuarioPlataforma.brokerPlataf.codPlataforma];
  } else if (usuarioPlataforma.procuradorPlataf) {
    plataforma = [usuarioPlataforma.procuradorPlataf.codPlataforma];
  }
  if (plataforma !== 0) {
    let existePlataforma = false;
    lstPlataformas = lstPlataformas.map(dados => {
      if (dados.value === plataforma[0]) existePlataforma = true;
      return dados;
    });

    if (!existePlataforma && lstPlataformas.length > 0) {
      plataforma = [lstPlataformas[0].value];
    }
  } else if (lstPlataformas.length > 0) {
    plataforma = [lstPlataformas[0].value];
  }
  return plataforma;
};

export const verificaRegrasSelecionaUnidadeNegocio = (usuarioPlataforma, lstUnidadeNegocio) => {
  let unidadeSelecionada = 0;
  let gestorPlataforma = false;

  if (getCodUnidadeEscolhido()) {
    unidadeSelecionada = getCodUnidadeEscolhido();
  } else if (usuarioPlataforma.brokerPlataf && usuarioPlataforma.brokerPlataf.gestor === 'S') {
    gestorPlataforma = true;
  }

  if (unidadeSelecionada !== 0) {
    let existeUnidade = false;
    lstUnidadeNegocio = lstUnidadeNegocio.map(dados => {
      if (dados.value === unidadeSelecionada) existeUnidade = true;
      return dados;
    });
    if (!existeUnidade && lstUnidadeNegocio.length > 0) {
      unidadeSelecionada = lstUnidadeNegocio[0].value;
    }
  } else if (lstUnidadeNegocio.length > 0 && gestorPlataforma) {
    unidadeSelecionada = 'todos';
  } else if (lstUnidadeNegocio.length > 0) {
    unidadeSelecionada = lstUnidadeNegocio[0].value;
  }
  return unidadeSelecionada;
};

export const verificaRegrasSelecionaGerente = (usuarioPlataforma, lstGerentes) => {
  let gerente = 0;

  if (getCodGerenteEscolhido()) {
    gerente = getCodGerenteEscolhido();
  } else if (usuarioPlataforma.brokerPlataf) {
    gerente = usuarioPlataforma.brokerPlataf.codBroker;
  }
  if (gerente !== 0) {
    let existeGerente = false;
    lstGerentes = lstGerentes.map(dados => {
      if (dados.value === gerente) existeGerente = true;
      return dados;
    });
    if (!existeGerente && lstGerentes.length > 0) {
      gerente = lstGerentes[0].value;
    }
  } else if (lstGerentes.length > 0) {
    gerente = lstGerentes[0].value;
  }
  return gerente;
};

export const useEffectAfterMount = (cb, dependencies) => {
  const mounted = useRef(true);

  useEffect(() => {
    if (!mounted.current) {
      return cb();
    }
    mounted.current = false;
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

export const criaCookieToken = md5User => {
  const configAmbiente = getConfigAmbiente();
  const dominio =
    configAmbiente && configAmbiente.sistema && configAmbiente.sistema.dominio
      ? configAmbiente.sistema.dominio
      : 'one7.in';
  const cookies = new Cookies();
  const expirationInSeconds = 15; // 15 segundos
  const expirationDate = new Date(Date.now() + expirationInSeconds * 1000); // Converte segundos para milissegundos

  deleteCookie(cookies, dominio);

  cookies.set(AUTH_COOKIE, md5User, {
    path: '/',
    expires: expirationDate,
    domain: dominio,
    secure: window.location.protocol === 'https:'
  });

  let objCliente = getClienteAtual();
  if (objCliente && Object.keys(objCliente).length > 0) {
    cookies.set(CLIENTE_SELECIONADO, objCliente, {
      path: '/',
      expires: expirationDate,
      domain: dominio,
      secure: window.location.protocol === 'https:'
    });
  }
};

export const deleteCookie = (cookies, dominio) => {
  cookies.set(AUTH_COOKIE, '', {
    path: '/',
    expires: new Date(0),
    domain: dominio,
    secure: window.location.protocol === 'https:'
  });
};

export const getDomainName = hostName => {
  return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1);
};

export const pegaLetrasIniciaisNome = nome => {
  if (nome && nome !== '' && nome !== null && nome.includes(' ')) {
    let palavra = nome.toLowerCase();
    palavra = palavra.replaceAll(/\s(de|da|do|dos|das)\s/g, ' ');
    let palavras = palavra.split(' ');
    let primeiraLetra = palavras[0].charAt(0).toUpperCase();
    let segundaLetra = palavras[1].charAt(0).toUpperCase();
    return primeiraLetra + segundaLetra;
  } else if (nome && nome !== '' && nome !== null) {
    let primeiraLetra = nome.charAt(0).toUpperCase();
    let segundaLetra = nome.charAt(1).toUpperCase();
    return primeiraLetra + segundaLetra;
  } else {
    return '';
  }
};

export const geraCorHexaDecimal = string => {
  let hash = 0;

  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let corHex = '#';

  for (let j = 0; j < 6; j++) {
    let value = (hash >> (j * 4)) & 0xff;
    corHex += value.toString(16).padStart(2, '0');
  }

  return corHex.substring(0, 7);
};

export const carregarEscopoAmbienteTenant = funcaoRetorno => {
  const configAmbiente = getConfigAmbiente();
  const dataHoje = parseInt(moment().format('YYMMDD'), 10);
  if (
    configAmbiente &&
    configAmbiente.sistema &&
    configAmbiente.dataEscopo &&
    configAmbiente.dataEscopo === dataHoje
  ) {
    funcaoRetorno(configAmbiente);
    return true;
  }
  return getEscopoAmbiente()
    .then(resEscopo => {
      // SETA AS CONFIGURACOES DE AMBIENTE
      if (resEscopo.data && resEscopo.data.sistema && resEscopo.data.sistema.dominio) {
        const escopoSalvo = setConfigAmbiente(resEscopo.data);
        if (escopoSalvo) {
          funcaoRetorno(escopoSalvo);
        } else {
          funcaoRetorno({});
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('carregarEscopoAmbienteTenant retorno nulo', resEscopo.data);
        setConfigAmbiente(false);
        funcaoRetorno({});
      }
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.error('carregarEscopoAmbienteTenant', err);
      setConfigAmbiente(false);
      funcaoRetorno({});
      throw err;
    });
};

export const carregarServicos = servicos => {
  if (servicos && servicos.HUBSPOT_CHAT) {
    carregaServicoHubspot(servicos.HUBSPOT_CHAT);
  }

  if (servicos && servicos.HUBSPOT_NPS) {
    carregaServicoHubspot(servicos.HUBSPOT_NPS);
  }
};

export const carregaServicoHubspot = linkServico => {
  if (!document.getElementById('hs-script-loader')) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.async = true;
    script.defer = true;
    script.src = linkServico;
    document.head.appendChild(script);
  }
};
