import API from './api';

export const getExpingTitles = () =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-titulos-a-vencer`);

export const postTitulosVencidos = dados =>
  API.post(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-titulos-vencidos`, dados);

export const postTitulosAvencer = dados =>
  API.post(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-titulos-a-vencer`, dados);

export const postLiquidezCarteira = dados =>
  API.post(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-liquidez`, dados);

export const postLimitesCarteira = dados =>
  API.post(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-limites-carteira`, dados);

export const postLimitesComercialCarteira = dados =>
  API.post(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-limites-comercial-carteira`, dados);

export const postUltimosBorderos = dados =>
  API.post(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-ultimos-borderos`, dados);

export const postStatusMesa = dados =>
  API.post(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-status-mesa`, dados);

export const getLiquidity = () =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-liquidez`);

export const getStats = () =>
  Promise.all([
    API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-total-instrucoes-pendentes`),
    API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-total-vencidos`)
  ]);

export const getOverdueTitles = () =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-titulos-vencidos`);

export const postConcentracaoSacado = filtro =>
  API.post(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-concentracao-sacado`, filtro);

export const getLastBordereaus = () =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-ultimos-borderos`);

export const getMostrarPesquisaCliente = () =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-mostrar-pesquisa-cliente`);

export const getMostrarPesquisaUsuario = () =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/mostrar-pesquisa-usuario`);

export const getMostrarPesquisaContaCorrente = () =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-mostrar-pesquisa-conta-corrente`);

export const alteraPesquisaCliente = value =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-alterar-pesquisa-cliente/${value}`);

export const alteraPesquisaUsuario = () =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/cadastrar-pesquisa-usuario`);

export const alteraPesquisaContaCorrente = value =>
  API.get(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-alterar-pesquisa-conta-corrente/${value}`
  );

export const getMostrarPesquisaWootric = () =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-mostrar-pesquisa-wootric`);

export const getListaPlataformasUsuario = () =>
  API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/dash-lista-plataformas`);

export const postListaClientesDashboard = params => {
  return API.post(
    `${process.env.REACT_APP_API_OPERACAO_BASE_URL}/clientes/lista-limites-diponiveis`,
    params
  );
};

export const postQuantidadeClientesDashboard = params => {
  return API.post(
    `${process.env.REACT_APP_API_OPERACAO_BASE_URL}/clientes/quantidade-lista-limites-diponiveis`,
    params
  );
};

export const getConjuntoEmpresasProdutos = codCliente =>
  API.get(
    `${process.env.REACT_APP_API_OPERACAO_BASE_URL}/clientes/conjunto-empresas-produtos/${codCliente}`
  );

export const postListarVOPMeta = params =>
  API.post(`${process.env.REACT_APP_API_OPERACAO_BASE_URL}/operacoes/listar-vop-meta`, params);

export const postListarOperacoesVOP = params =>
  API.post(`${process.env.REACT_APP_API_OPERACAO_BASE_URL}/operacoes/listar-operacoes-vop`, params);

export const postListarPropostasEsteira = params =>
  API.post(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/listar-esteira-propostas`,
    params
  );

export const getListaPropostasPareceres = (codppc, mostrarTudo) => {
  const params = {
    mostrar_tudo: mostrarTudo
  };
  return API.get(
    `${process.env.REACT_APP_API_PROPOSTAS_CONTRATOS_BASE_URL}/proposta/pareceres-estados/${codppc}`,
    {
      params
    }
  );
};

export const getPareceresClientes = (cnpj, mostrarTudo) => {
  const params = {
    mostrar_tudo: mostrarTudo
  };
  return API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/clientes/pareceres/${cnpj}`, {
    params
  });
};

export const getMostrarModalPesquisaNovidades = () =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/mostrar-pesquisa-novidades`);

export const cadastraUsuarioPesquisaNovidades = params =>
  API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/cadastrar-pesquisa-novidades`,
    params
  );

export const getMostrarModalBannerClientes = () =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/mostrar-banner-clientes`);

export const cadastraUsuarioBannerClientes = params =>
  API.post(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/usuarios/cadastrar-banner-clientes`, params);

export const postVopSpreadComercial = params =>
  API.post(
    `${process.env.REACT_APP_API_OPERACAO_BASE_URL}/operacoes/listar-vop-spread-comercial`,
    params
  );

// CHECKPOINTS
export const getListarCheckpoints = () => {
  return API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/checkpoint/checkpoints`);
};

export const getListarCheckpointsPropostas = cnpj => {
  return API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/checkpoint/checkpoints-propostas/${cnpj}`
  );
};

export const getListarCheckpointsNegocios = cnpj => {
  return API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/checkpoint/checkpoints-negocios/${cnpj}`
  );
};

export const postSalvarCheckpointsPropostas = params =>
  API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/checkpoint/cadastrar-checkpoints-propostas`,
    params
  );

export const postSalvarCheckpointsNegocios = params =>
  API.post(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/checkpoint/cadastrar-checkpoints-negocios`,
    params
  );

export const getListarLimiteComercial = codCliente =>
  API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/limite-comercial/listar/${codCliente}`);

export const postSalvarLimiteComercial = params =>
  API.put(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/limite-comercial/salvar`, params);
