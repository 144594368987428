import React, { useState } from 'react';
import { Alert, AlertTitle, Box, Grid, Snackbar } from '@mui/material';

import StatusMesa from './StatusMesa/StatusMesa';
import VOP from './Charts/VOP/VOP';
import LimitesCarteira from './Charts/LimitesCarteira/LimitesCarteira';
import LimitesCarteiraCompacto from './Charts/LimitesCarteira/LimitesCarteiraCompacto';
import Vencidos from './Charts/Vencidos/Vencidos';
import Liquidez from './Charts/Liquidez/Liquidez';
import Avencer from './Charts/Avencer/Avencer';
import ConcentracaoSacado from './Charts/ConcentracaoSacado/ConcentracaoSacado';

import FiltroDashboard from './FiltroDashboard';
import LoaderMain from '../Onboarding/shared/LoaderMain';
import UltimosBorderos from './Grids/UltimosBorderos/UltimosBorderos';
import VisaoGeral from './VisaoGeral';
import VOPSpread from './Charts/VOPSpread/VOPSpread';
import { nivelUsuarioEnum } from '../../shared/Enums';

const DashboardGeral = ({
  clienteAtual,
  dadosUser,
  loadingDashFim,
  telaLoading,
  valorTotalVencidos,
  valorTotalAVencer,
  setValorTotalVencidos,
  setValorTotalAVencer,
  valoresCalculados,
  qtdeClientesAVencer,
  setQtdeClientesAVencer,
  escopoAcesso
}) => {
  const filtroInicial = clienteAtual && clienteAtual.id === 'todos' ? { filtrar: false } : {};
  const [filtroDashboard, setFiltroDashboard] = useState(filtroInicial);
  const [filterIsPending, setFilterIsPending] = useState(
    clienteAtual && clienteAtual.id === 'todos' ? true : false
  );
  const [platafUser, setPlatafUser] = useState({});
  const [alerta, setAlerta] = useState({});

  const handleCloseAlerta = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlerta({});
  };

  return (
    <Box sx={filterIsPending ? { position: 'relative' } : {}} className="dashboard-geral">
      {filterIsPending && <LoaderMain tipoLoader="float" tipoImagem="logo" bgTransparencia="1" />}
      {clienteAtual && clienteAtual.id === 'todos' && (
        <Box className="container-box">
          <FiltroDashboard
            setFiltroDashboard={setFiltroDashboard}
            clienteAtual={clienteAtual}
            filterIsPending={filterIsPending}
            setFilterIsPending={setFilterIsPending}
            dadosUser={dadosUser}
            loadingDashFim={loadingDashFim}
            telaLoading={telaLoading}
            setPlatafUser={setPlatafUser}
            escopoAcesso={escopoAcesso}
          />
        </Box>
      )}
      <Box className="charts-geral">
        {filtroDashboard &&
          (filtroDashboard.filtrar !== false || filtroDashboard.filtrar === undefined) && (
            <>
              {(filtroDashboard.filtrar === undefined ||
                filtroDashboard.filtrar === 'carga-inicial') && (
                <Box className="container-box">
                  <Grid container spacing={3}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <StatusMesa
                        filtroDashboard={filtroDashboard}
                        clienteAtual={clienteAtual}
                        loadingDashFim={loadingDashFim}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              {filtroDashboard.filtrar === undefined || filtroDashboard.filtrar === true ? (
                <>
                  <Box className="container-box charts-vop-limites-carteira">
                    <Grid container spacing={3}>
                      {(clienteAtual && clienteAtual.id !== 'todos') ||
                      (platafUser &&
                        platafUser.backofficePlataf &&
                        platafUser.backofficePlataf.length > 0 &&
                        filtroDashboard &&
                        filtroDashboard.plataforma &&
                        platafUser.backofficePlataf.includes(parseInt(filtroDashboard.plataforma)) ===
                          false) ? (
                        // eslint-disable-next-line react/jsx-indent
                        <>
                          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <UltimosBorderos
                              filtroDashboard={filtroDashboard}
                              clienteAtual={clienteAtual}
                              loadingDashFim={loadingDashFim}
                            />
                          </Grid>
                          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <LimitesCarteiraCompacto
                              filtroDashboard={filtroDashboard}
                              clienteAtual={clienteAtual}
                              loadingDashFim={loadingDashFim}
                              dadosUser={dadosUser}
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <LimitesCarteira
                              filtroDashboard={filtroDashboard}
                              clienteAtual={clienteAtual}
                              loadingDashFim={loadingDashFim}
                            />
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <VOP
                              filtroDashboard={filtroDashboard}
                              clienteAtual={clienteAtual}
                              loadingDashFim={loadingDashFim}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Box>
                  <Box className="container-box charts-vencidos-avencer">
                    <Grid container spacing={3}>
                      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <Vencidos
                          filtroDashboard={filtroDashboard}
                          clienteAtual={clienteAtual}
                          loadingDashFim={loadingDashFim}
                          valorTotal={valorTotalVencidos}
                          setValorTotal={setValorTotalVencidos}
                          valoresCalculados={valoresCalculados}
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <Avencer
                          filtroDashboard={filtroDashboard}
                          clienteAtual={clienteAtual}
                          loadingDashFim={loadingDashFim}
                          valorTotal={valorTotalAVencer}
                          setValorTotal={setValorTotalAVencer}
                          valoresCalculados={valoresCalculados}
                          qtdeClientes={qtdeClientesAVencer}
                          setQtdeClientes={setQtdeClientesAVencer}
                          dadosUser={dadosUser}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className="container-box charts-liquidez-concentracao">
                    <Grid container spacing={3}>
                      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <Liquidez
                          filtroDashboard={filtroDashboard}
                          clienteAtual={clienteAtual}
                          loadingDashFim={loadingDashFim}
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <ConcentracaoSacado
                          filtroDashboard={filtroDashboard}
                          clienteAtual={clienteAtual}
                          loadingDashFim={loadingDashFim}
                          dadosUser={dadosUser}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {clienteAtual &&
                  clienteAtual.id === 'todos' &&
                  dadosUser &&
                  parseInt(dadosUser.nivel) !== nivelUsuarioEnum.GERENTE_NEGOCIOS ? (
                    <Box className="container-box">
                      <Grid container spacing={3}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <VOPSpread
                            filtroDashboard={filtroDashboard}
                            clienteAtual={clienteAtual}
                            loadingDashFim={loadingDashFim}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    loadingDashFim('VopSpread')
                  )}
                  <Box className="container-box charts-clientes-grid">
                    <Grid container spacing={3}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <VisaoGeral
                          filtroDashboard={filtroDashboard}
                          clienteAtual={clienteAtual}
                          loadingDashFim={loadingDashFim}
                          dadosUser={dadosUser}
                          escopoAcesso={escopoAcesso}
                          setAlerta={setAlerta}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : (
                filtroDashboard.filtrar === 'carga-inicial' && (
                  <Alert severity="info">
                    <AlertTitle>Atenção</AlertTitle>
                    Para as demais informações favor filtrar!
                  </Alert>
                )
              )}
            </>
          )}
      </Box>
      <Snackbar
        open={alerta.mensagem ? true : false}
        autoHideDuration={5000}
        onClose={handleCloseAlerta}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        className="snackbar-cliente"
      >
        <Alert
          onClose={handleCloseAlerta}
          severity={alerta.tipo ? alerta.tipo : 'success'}
          className="w-100"
        >
          <span className="w-100 texto-semi-negrito font-1rem">
            {alerta.mensagem ? alerta.mensagem : ''}
          </span>
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default DashboardGeral;
