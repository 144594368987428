import React, { memo, useState, useEffect } from 'react';
import { KeyboardArrowUp, KeyboardArrowDown, Search, Add } from '@mui/icons-material';
import { Box, Button, OutlinedInput, useTheme, useMediaQuery } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Redirect, useLocation } from 'react-router';
import swal from '@sweetalert/with-react';
import {
  getClientes,
  getClienteAtual,
  getDadosUsuario,
  isAuthenticated,
  setClientes
} from '../../../shared';
import { nivelUsuarioEnum } from '../../../shared/Enums';
import { getClientesUsuario } from '../../../api/clientes';
import { getConfigAmbiente } from '../../../shared/services/User';
import LoaderMain from '../../Onboarding/shared/LoaderMain';

const UsuarioLogado = ({ setMostraFundoEscuro, fixed }) => {
  const history = useHistory();
  const clienteAtual = getClienteAtual();
  const clientes = getClientes();
  const autenticado = isAuthenticated();
  const [mostrarListaClientes, setMostrarListaClientes] = useState(false);
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [novoCliente, setNovoCliente] = useState(null);
  const [nomeUsuarioLogado, setNomeUsuarioLogado] = useState('');
  const [dadosUserLogado, setDadosUserLogado] = useState(false);
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const limitacaoClientesMostra = 500;
  const location = useLocation();
  const [isPending, setPending] = useState(false);
  const [valuePesquisa, setValuePesquisa] = useState('');
  const config = getConfigAmbiente();
  const corSecundariaCliente =
    config && config.cores && config.cores.CORSECUNDARIACLIENTE
      ? config.cores.CORSECUNDARIACLIENTE
      : '#FFFFFF';
  const corBaseCliente =
    config && config.cores && config.cores.CORBASECLIENTE ? config.cores.CORBASECLIENTE : '#333333';

  const abrirListaClientes = () => {
    let tipo = mostrarListaClientes ? false : true;
    setMostraFundoEscuro(tipo);
    setMostrarListaClientes(tipo);
  };

  const searchHandler = term => {
    setValuePesquisa(term);
  };

  const clickBuscarClientes = () => {
    if (dadosUserLogado && dadosUserLogado.nivel !== nivelUsuarioEnum.CLIENTE) {
      buscaClientesUsuario(valuePesquisa);
    } else {
      filtragemClientes(valuePesquisa);
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' && valuePesquisa.length > 3) {
      clickBuscarClientes();
    }
  };

  const filtragemClientes = (string, lstClientes = []) => {
    setFilteredClientes([]);
    string = string.toLowerCase();

    let lstFiltragem = [];

    if (lstClientes && lstClientes.length > 0) {
      lstFiltragem = lstClientes;
    } else {
      lstFiltragem = clientes;
    }

    let clientsFiltrados = [
      ...lstFiltragem.filter(cliente => {
        if (
          (cliente.razaoSocial !== null &&
            cliente.id !== 'todos' &&
            cliente.razaoSocial.toLowerCase().includes(string)) ||
          cliente.cnpj.includes(string)
        ) {
          return cliente.razaoSocial.toLowerCase().includes(string) || cliente.cnpj.includes(string);
        }
        return false;
      })
    ];

    let arrayClientes = clientsFiltrados.slice(0, limitacaoClientesMostra);
    setFilteredClientes(arrayClientes);
  };

  const buscaClientesUsuario = string => {
    if ((string && string !== '') || isValidCNPJ(string)) {
      let stringSanitizedToSearch = '';

      const stringNumericaToSearch = isOnlyNumbers(string);

      if (stringNumericaToSearch === true && isValidCNPJ(string)) {
        stringSanitizedToSearch = string.replace(/\D/g, '');
      } else {
        stringSanitizedToSearch = string;
      }

      setPending(true);
      getClientesUsuario(stringSanitizedToSearch)
        .then(retorno => {
          let lstNovosClientes =
            retorno.data && retorno.data.lista_clientes ? retorno.data.lista_clientes : [];
          const listaAtualizada = atualizarLista(clientes, lstNovosClientes, 'cnpj');

          setClientes(listaAtualizada);
          filtragemClientes(stringSanitizedToSearch, listaAtualizada);
        })
        .catch(() => {})
        .finally(() => {
          setPending(false);
        });
    }
  };

  const isValidCNPJ = input => {
    // Remove todos os caracteres não numéricos
    const sanitized = input.replace(/\D/g, '');

    // Verifica se o resultado tem exatamente 14 dígitos
    return sanitized.length === 14;
  };

  const isOnlyNumbers = input => {
    const sanitized = input.replace(/\D/g, '');
    const regex = /^\d+$/;
    return regex.test(sanitized);
  };

  const atualizarLista = (listaOriginal, novosObjetos, chaveUnica) => {
    let listaAtualizada = [...listaOriginal];
    novosObjetos.forEach(novoObjeto => {
      const objetoExiste = listaOriginal.some(objeto => objeto[chaveUnica] === novoObjeto[chaveUnica]);
      if (!objetoExiste) {
        listaAtualizada.push(novoObjeto);
      }
    });
    return listaAtualizada;
  };

  useEffect(() => {
    pegaNomeUsuario();
    let arrayClientes = clientes ? clientes.slice(0, limitacaoClientesMostra) : [];
    setFilteredClientes(arrayClientes);
  }, []);

  const pegaNomeUsuario = () => {
    let dadosUser = getDadosUsuario();
    let nomeUsuario = dadosUser && dadosUser.nome ? dadosUser.nome : '';
    nomeUsuario =
      nomeUsuario && nomeUsuario !== '' && nomeUsuario !== null && nomeUsuario.includes(' ')
        ? nomeUsuario.split(' ')[0]
        : nomeUsuario;
    setNomeUsuarioLogado(nomeUsuario);
    setDadosUserLogado(dadosUser);
  };

  const trocarEmpresa = cliente => {
    let titulo = 'Trocar para outra empresa';
    if (cliente.id === 'todos') {
      titulo = 'Trocar para todas as empresas';
    }
    swal({
      title: titulo,
      text:
        'Ao confirmar esta ação você será redirecionado para outra sessão. Você quer mesmo continuar?',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
      className: 'alert-trocar-empresa'
    }).then(confirm => {
      if (confirm) {
        setNovoCliente(cliente);
      }
    });
  };

  const abrirTela = tela => {
    history.push({
      pathname: `/${tela}`
    });
  };

  return (
    <>
      {novoCliente && (
        <Redirect
          to={{
            pathname: '/authentication/change-client',
            state: { cliente: novoCliente, location }
          }}
        />
      )}

      {autenticado && (
        <Box
          className={`box-usuario-logado flex-box ${fixed ? 'fixed-usuario-logado' : ''}`}
          onBlur={e => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
              setMostrarListaClientes(false);
              setMostraFundoEscuro(false);
            }
          }}
        >
          {/* <IconButton className="gtm-botao-notificacoes" size="small">
            <NotificationsNone />
          </IconButton> */}
          <div
            onClick={abrirListaClientes}
            onKeyDown={abrirListaClientes}
            role="button"
            tabIndex="0"
            className={onlySmallScreen ? 'box-usuario responsive' : 'box-usuario'}
          >
            <div
              className="imagem-usuario-logado"
              style={{ backgroundColor: corBaseCliente, color: corSecundariaCliente }}
            >
              {nomeUsuarioLogado.substr(0, 1).toUpperCase()}
            </div>
            <div
              className={onlySmallScreen ? 'box-responive box-usuario-empresa' : 'box-usuario-empresa'}
            >
              <div className={clienteAtual && clienteAtual.razaoSocial ? 'nome-usuario-logado' : ''}>
                {nomeUsuarioLogado.substr(0, 18).toUpperCase()}
              </div>
              <div className="nome-empresa-selecionada white-space-nowrap">
                {onlySmallScreen && clienteAtual && clienteAtual.razaoSocial
                  ? (clienteAtual.razaoSocial.substr(0, 13) + '...').toUpperCase()
                  : clienteAtual && clienteAtual.razaoSocial
                  ? clienteAtual.razaoSocial.length > 20
                    ? (clienteAtual.razaoSocial.substr(0, 18) + '...').toUpperCase()
                    : clienteAtual.razaoSocial.toUpperCase()
                  : ''}
              </div>
            </div>
            <div className="icone-arrow-down" style={{ color: corBaseCliente }}>
              {mostrarListaClientes ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </div>
          </div>

          {mostrarListaClientes && (
            <div className={onlySmallScreen ? 'box-clientes responsive' : 'box-clientes'}>
              {clientes && clientes.length > 0 && (
                <>
                  <div className="flex-box items-centro-verticalmente">
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type="text"
                      className="input-pesquisa-cliente"
                      onChange={e => searchHandler(e.target.value)}
                      onKeyDown={handleKeyDown}
                      placeholder="Pesquisar por Nome ou CNPJ"
                      autoComplete="off"
                      error={valuePesquisa.length > 0 && valuePesquisa.length <= 3 ? true : false}
                      autoFocus
                      value={valuePesquisa}
                    />
                    <Button
                      className="btn-filter-clientes"
                      onClick={() => clickBuscarClientes()}
                      disabled={valuePesquisa.length <= 3}
                    >
                      <Search />
                    </Button>
                  </div>

                  <Box className="lista-clientes">
                    {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
                    {filteredClientes.map(cliente => (
                      <div key={cliente.id}>
                        {cliente.id !== (clienteAtual && clienteAtual.id ? clienteAtual.id : 0) && (
                          <Button
                            key={cliente.id}
                            type="button"
                            title={`CNPJ: ` + cliente.cnpj}
                            onClick={() => trocarEmpresa(cliente)}
                          >
                            {cliente.razaoSocial.length > 23
                              ? (cliente.razaoSocial.substr(0, 21) + '...').toUpperCase()
                              : cliente.razaoSocial.toUpperCase()}
                          </Button>
                        )}
                      </div>
                    ))}
                  </Box>
                </>
              )}

              {dadosUserLogado && (
                <div className="link-todas-empresas">
                  {clientes &&
                    Object(clientes).length > 1 &&
                    (clienteAtual && clienteAtual.id ? clienteAtual.id : 0) !== 'todos' && (
                      <Button
                        type="button"
                        title="Selecionar todas as empresas"
                        onClick={() =>
                          trocarEmpresa({
                            clienteEspecial: false,
                            clienteOperaConnect: false,
                            cnpj: 'todos',
                            codCliente: 'todos',
                            dadosBrokerCliente: null,
                            id: 'todos',
                            nomeFantasia: 'TODAS AS EMPRESAS',
                            operaTrustee: 'N',
                            razaoSocial: 'TODAS AS EMPRESAS'
                          })
                        }
                      >
                        Acessar todas as empresas
                      </Button>
                    )}
                </div>
              )}

              <div className="link-cadastrar-empresa">
                <Button
                  type="button"
                  className="link-cadastrar-empresa"
                  title="Selecionar todas as empresas"
                  onClick={() => abrirTela('entrada-cnpj-onboarding')}
                  sx={{ width: '100%', textTransform: 'none' }}
                >
                  <Add />
                  Cadastrar outra empresa
                </Button>
              </div>

              <div>
                <Button
                  type="button"
                  className="texto-cor-preto link-logout"
                  title="Sair do sistema"
                  onClick={() => abrirTela('authentication/logout')}
                >
                  Encerrar sessão
                </Button>
              </div>
            </div>
          )}
        </Box>
      )}
    </>
  );
};
export default memo(UsuarioLogado);
