import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label
} from 'reactstrap';
import { Link } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import { Form, Formik, FastField, Field } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import {
  getClienteAtual,
  getCodPlataformaEscolhida,
  setCodPlataformaEscolhida,
  verificaMostraFiltroPlataforma
} from '../../shared/services/User';
import {
  getTiposInstrucao,
  getBoletos,
  getInstrucoesListaResumida,
  postCancelarInstrucoes,
  getInstrucoesPareceres,
  getInstrucoesAnexos,
  getEmailInstrucoesAnexos
} from '../../api/instrucoes';

import {
  SkeletonLoader,
  DateTimePicker,
  Input,
  Select,
  listarPlataformaEListarGerentes,
  listaFiltradaGerentesPlataforma,
  listaFiltradaUnidadeNegocioPlataforma,
  normalizaListaGerentesPlataforma,
  normalizaListaUnidadesNegociosPlataforma,
  getCodGerenteEscolhido,
  getCodUnidadeEscolhido,
  setCodGerenteEscolhido,
  setCodUnidadeEscolhido,
  setCodVerticalEscolhida
} from '../../shared';
import { dateFormat, moneyFullFormat } from '../../utils/format';
import copyToClipboard from '../../utils/copyToClipboard';
import Main from '../Main/Main';

import Grid from '../commom/Grid';
import instrucoesGrid from './instrucoesGrid';

import ModalParecerInstrucao from './ModalParecer/ModalParecerInstrucao';
import ModalAnexoInstrucao from './ModalAnexoInstrucao/ModalAnexoInstrucao';
import HistoricoAcesso from '../commom/HistoricoAcesso';

const Instrucoes = props => {
  const [form, setForm] = useState({});
  const [isPending, setPending] = useState(true);
  const [itensCount, setItensCount] = useState(0);
  const [instrucoes, setInstrucoes] = useState([]);
  const [clienteAtual, setClienteAtual] = useState();
  const [tiposInstrucao, setTiposIntrucao] = useState();
  const [checkedAll, setCheckedAll] = useState({ checked: false, disabled: false });
  const [modalCancel, setModalCancelar] = useState({ show: false });
  const [modalBoleto, setModalBoleto] = useState({ show: false });
  // Douglas - 24/01/20 - Ao carregar a pagina, caso o tamanho da janela seja menor ou igual a 600px ja carrega o menu collapsado
  const [collapseMenuRight, setCollapseMenuRight] = useState(window.innerWidth <= 600 ? true : false);
  const [message, setMessage] = useState();
  const [modalParecerInstrucao, setModalParecerInstrucao] = useState({ show: false });
  const [modalAnexoInstrucao, setModalAnexoInstrucao] = useState({
    show: false,
    arquivos: [],
    codInstrucao: 0,
    codTipoInstrucao: 0
  });
  const [vertical, setVertical] = useState(0);
  const [lstVerticais, setLstVerticais] = useState([]);
  const [plataforma, setPlataforma] = useState(0);
  const [lstPlataformas, setLstPlataformas] = useState([]);
  const [lstPlataformasFiltradas, setLstPlataformasFiltradas] = useState([]);
  const mostrarFiltroPlataforma = verificaMostraFiltroPlataforma();
  const [lstFiltradaGerentePlataforma, setLstFiltradaGerentePlataforma] = useState([]);
  const [gerente, setGerente] = useState(null);
  const [lstFiltradaUnidadeNegocio, setLstFiltradaUnidadeNegocio] = useState([]);
  const [unidadeNegocio, setUnidadeNegocio] = useState(null);

  const filtroInicial = {
    data_inicio: moment()
      .subtract(14, 'days')
      .format('DD/MM/YYYY'),
    data_final: moment().format('DD/MM/YYYY')
  };

  const getInstrucoes = (filtro = {}, actions = null) => {
    setPending(true);
    setInstrucoes([]);
    setItensCount(0);
    return getInstrucoesListaResumida(filtro)
      .then(res => {
        const instrucoes = res.data;
        if (Array.isArray(instrucoes)) {
          instrucoes.map(instrucao => {
            instrucao.meta = {
              visible: true,
              disabled: false,
              checked: false
            };

            instrucao.valor = instrucao.valor ? moneyFullFormat(instrucao.valor) : 'R$ 0,00';
            instrucao.valorTitulo = instrucao.valorTitulo
              ? moneyFullFormat(instrucao.valorTitulo)
              : 'R$ 0,00';

            instrucao.vencimento = instrucao.vencimento ? dateFormat(instrucao.vencimento) : '';
            return instrucao;
          });
          setPending(false);
          setInstrucoes(instrucoes);
          setItensCount(0);
        }
      })
      .finally(() => {
        setPending(false);
        if (actions !== null) {
          actions.setSubmitting(false);
          setCollapseMenuRight(true);
        }
      });
  };

  useEffect(() => {
    setPending(true);
    if (!clienteAtual) {
      const newCliente = getClienteAtual();
      setClienteAtual(newCliente);
    }

    if (!tiposInstrucao) {
      getTiposInstrucao().then(res => {
        var tiposInstr = [];
        res.data.forEach(item => {
          var instr = {
            value: item.code,
            label: item.descricao
          };
          tiposInstr.push(instr);
        });
        setTiposIntrucao(tiposInstr);
      });
    }

    if (!mostrarFiltroPlataforma) {
      getInstrucoes(filtroInicial);
    }

    buscaPlataformas();
  }, []);

  const buscaPlataformas = () => {
    if (!mostrarFiltroPlataforma) {
      return;
    }
    setPending(true);
    listarPlataformaEListarGerentes({
      setLstPlataformas: setLstPlataformas,
      setPlataforma: setPlataforma,
      setLstPlataformasFiltradas: setLstPlataformasFiltradas,
      setListaFiltradaGerentes: setLstFiltradaGerentePlataforma,
      setGerenteEscolhido: setGerente,
      setListaFiltradaUnidadeNegocio: setLstFiltradaUnidadeNegocio,
      setUnidadeNegocioEscolhido: setUnidadeNegocio,
      funcaoSucesso: funcaoSucessoPlataforma,
      setPending: setPending,
      setLstVerticais: setLstVerticais,
      setVertical: setVertical
    });
  };

  const funcaoSucessoPlataforma = (vertical, plat, ger, unid) => {
    let filtros = filtroInicial;
    filtros.cod_vertical = vertical;
    filtros.cod_plataforma = plat;
    filtros.cod_gerente = ger;
    filtros.cod_unidade_negocio = unid;
    getInstrucoes(filtros);
  };

  // # Fica aqui comentado para conhecimento do search que estava anteriormente
  // const onSearch = value => {
  //   const newInstrucoes = [...instrucoes];

  //   newInstrucoes.forEach(instrucao => {
  //     // faz busca pelo nome do sacado ou pelo nº do documento ou pelo valor formatado ou pelo status da instrução ou pela data de vencimento
  //     if (
  //       value.length > 0 &&
  //       (instrucao.sacado.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
  //         instrucao.documento.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
  //         moneyFullFormat(instrucao.valor).indexOf(value) >= 0 ||
  //         instrucao.tipoInstrucao.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
  //         instrucao.statusAnaliseInstrucao.descricao.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
  //         dateFormat(instrucao.vencimento).indexOf(value) >= 0)
  //     ) {
  //       instrucao.meta.visible = true;
  //     } else if (value.length === 0) {
  //       instrucao.meta.visible = true;
  //     } else {
  //       instrucao.meta.visible = false;
  //     }
  //   });

  //   setInstrucoes(newInstrucoes);
  // };

  const setCorBtnAnexo = (value, cor) => {
    const newInstrucoes = [...instrucoes];
    newInstrucoes.forEach(instrucao => {
      if (value === instrucao.id.toString()) {
        instrucao.corBtnAnexo = cor;
      }
    });

    setInstrucoes(newInstrucoes);

    let btnAnexo = document.getElementById(`btn-anexoInstrucao-${value}`);
    if (cor === 'danger') {
      btnAnexo.classList.remove(`btn-outline-primary`);
      btnAnexo.classList.add(`btn-outline-${cor}`);
    } else {
      btnAnexo.classList.remove(`btn-outline-danger`);
      btnAnexo.classList.add(`btn-outline-${cor}`);
    }
  };

  const handleCheck = event => {
    const { checked, value } = event.target;
    const newInstrucoes = [...instrucoes];
    let intensCount = 0;

    newInstrucoes.forEach(instrucao => {
      if (value === instrucao.id.toString()) {
        instrucao.meta.checked = checked;
      }

      if (instrucao.meta.checked) {
        intensCount++;
      }
    });

    setItensCount(intensCount);
    setInstrucoes(newInstrucoes);
  };

  const handlerSelectAction = event => {
    const { value } = event.target;
    event.preventDefault();

    if (value === 'emitir_boleto') {
      modalBoletoShow();
    } else if (value === 'cancelar') {
      setModalCancelar({ show: true });
    }
  };

  const modalBoletoShow = () => {
    setModalBoleto({ isPending: true, show: true });

    const instrucoesSelecionados = instrucoes.filter(instrucao => {
      return instrucao.meta.checked;
    });
    const distinctInstrucoes = [...new Set(instrucoesSelecionados.map(x => x.id))];

    getBoletos(distinctInstrucoes).then(boletos => {
      setModalBoleto({ data: boletos.data, show: true, isPending: false });
    });
  };

  const modalCancelToggle = () => {
    const show = !modalCancel.show;
    setModalCancelar({ show });
  };

  const modalCancelConfirm = () => {
    setPending(true);
    setModalCancelar(false);
    setMessage();
    const instrucoesSelecionados = instrucoes.filter(instrucao => {
      return instrucao.meta.checked;
    });
    const distinctInstrucoes = [...new Set(instrucoesSelecionados.map(x => x.id))];
    postCancelarInstrucoes(distinctInstrucoes)
      .then(() => {
        swal({
          text: 'Instrução cancelada.'
        });
        getInstrucoes();
      })
      .catch(err => {
        setPending(false);
        const {
          response: { data: { error: { message = 'Erro inesperado ao cancelar' } = {} } = {} } = {}
        } = err;
        setMessage(message);
      });
  };

  const toggleModalParecerInstrucao = (codOperacaoInstrucao, statusAnaliseInstrucao) => {
    let idInstrucao = -1;
    let exibirTela = !modalParecerInstrucao.show;
    if (exibirTela === true) {
      idInstrucao = codOperacaoInstrucao;
    }

    getInstrucoesPareceres(idInstrucao).then(result => {
      setModalParecerInstrucao({
        show: exibirTela,
        idInstrucao: idInstrucao,
        cliente: clienteAtual,
        pareceres: result.data.pareceres,
        tiposParecer: result.data.tipos,
        encaminharPara: result.data.encaminharPara,
        statusAnaliseInstrucao: statusAnaliseInstrucao
      });
    });
  };

  const toggleModalAnexoInstrucao = (codInstrucao, codTipoInstrucao) => {
    getInstrucoesAnexos(codInstrucao).then(result => {
      setModalAnexoInstrucao({
        show: true,
        arquivos: result.data,
        codInstrucao: codInstrucao,
        codTipoInstrucao: codTipoInstrucao
      });
    });
  };

  const fecharModalInstrucao = () => {
    setModalAnexoInstrucao({
      show: false,
      arquivos: [],
      codInstrucao: 0,
      codTipoInstrucao: 0
    });
  };

  const enviarEmailAnexo = codInstrucao => {
    getEmailInstrucoesAnexos(codInstrucao).then(() => {});
  };

  const handleCheckAll = event => {
    let { checked } = event.target;
    let newInstrucoes = [...instrucoes];

    let intensCount = 0;
    newInstrucoes.forEach(item => {
      item.meta.checked = checked;
      if (item.meta.checked) {
        intensCount++;
      }
    });

    setItensCount(intensCount);
    setInstrucoes(newInstrucoes);
    setCheckedAll({ checked: checked, disabled: false });
  };

  useEffect(() => {
    if (form && form.setFieldValue && vertical && lstPlataformas.length > 0) {
      form.setFieldValue('cod_vertical', vertical);

      let plataformas = [...lstPlataformas].filter(
        plataf => parseInt(plataf.codVertical) === parseInt(vertical)
      );
      setLstPlataformasFiltradas(plataformas);

      let platafEscolhida =
        plataformas && plataformas[0] && plataformas[0].value ? plataformas[0].value : null;
      if (
        getCodPlataformaEscolhida() &&
        plataformas &&
        plataformas.filter(e => e.value === getCodPlataformaEscolhida()[0]).length > 0
      ) {
        platafEscolhida = getCodPlataformaEscolhida()[0];
      }
      setPlataforma(platafEscolhida);
    }
  }, [vertical]);

  useEffect(() => {
    if (form && form.setFieldValue && plataforma) {
      form.setFieldValue('cod_plataforma', Array.isArray(plataforma) ? plataforma[0] : plataforma);

      let lstFiltradaUnidadeNegocioPlataforma = listaFiltradaUnidadeNegocioPlataforma(
        Array.isArray(plataforma) ? plataforma : [plataforma]
      );

      let unidadeNegocioEscolhido = null;
      if (lstFiltradaUnidadeNegocioPlataforma.length > 0) {
        lstFiltradaUnidadeNegocioPlataforma = normalizaListaUnidadesNegociosPlataforma(
          lstFiltradaUnidadeNegocioPlataforma
        );
        setLstFiltradaUnidadeNegocio(lstFiltradaUnidadeNegocioPlataforma);
        unidadeNegocioEscolhido =
          lstFiltradaUnidadeNegocioPlataforma &&
          lstFiltradaUnidadeNegocioPlataforma[0] &&
          lstFiltradaUnidadeNegocioPlataforma[0].value
            ? lstFiltradaUnidadeNegocioPlataforma[0].value
            : null;

        if (
          getCodUnidadeEscolhido() &&
          lstFiltradaUnidadeNegocioPlataforma &&
          lstFiltradaUnidadeNegocioPlataforma.filter(e => e.value === getCodUnidadeEscolhido()).length >
            0
        ) {
          unidadeNegocioEscolhido = getCodUnidadeEscolhido();
        }
      } else {
        setLstFiltradaUnidadeNegocio(lstFiltradaUnidadeNegocioPlataforma);
      }
      setUnidadeNegocio(unidadeNegocioEscolhido);
    }
  }, [plataforma]);

  useEffect(() => {
    if (form && form.setFieldValue && plataforma) {
      form.setFieldValue('cod_unidade_negocio', unidadeNegocio);

      let lstFiltradaGerentes = listaFiltradaGerentesPlataforma(plataforma, unidadeNegocio);
      lstFiltradaGerentes = normalizaListaGerentesPlataforma(lstFiltradaGerentes);
      setLstFiltradaGerentePlataforma(lstFiltradaGerentes);

      let gerenteEscolhido =
        lstFiltradaGerentes && lstFiltradaGerentes[0] && lstFiltradaGerentes[0].value
          ? lstFiltradaGerentes[0].value
          : null;

      if (
        getCodGerenteEscolhido() &&
        lstFiltradaGerentes &&
        lstFiltradaGerentes.filter(e => e.value === getCodGerenteEscolhido()).length > 0
      ) {
        gerenteEscolhido = getCodGerenteEscolhido();
      }
      setGerente(gerenteEscolhido);
    }
  }, [unidadeNegocio, plataforma]);

  useEffect(() => {
    if (form && form.setFieldValue && plataforma) {
      form.setFieldValue('cod_gerente', gerente);
    }
  }, [gerente]);

  const handleSubmitFiltro = (filtro = {}, actions = null) => {
    setCodVerticalEscolhida(vertical);
    setCodPlataformaEscolhida([plataforma]);
    setCodUnidadeEscolhido(unidadeNegocio);
    setCodGerenteEscolhido(gerente);
    getInstrucoes(filtro, actions);
  };

  return (
    <>
      <Main
        title="Instruções"
        className={'instrucoes-grid instrucoes ' + (collapseMenuRight ? 'collapsed' : '')}
        {...props}
      >
        <Formik
          ref={node => setForm(node)}
          initialValues={filtroInicial}
          validationSchema={Yup.object().shape({
            data_inicio: Yup.string()
              .nullable()
              .required('Período obrigatório.'),
            data_final: Yup.string()
              .nullable()
              .required('Período obrigatório.')
          })}
          onSubmit={handleSubmitFiltro}
          render={({ isSubmitting }) => {
            return (
              <Card>
                <CardBody>
                  <div className="row row-content-instrucoes row-content">
                    <div
                      id="content-instrucoes"
                      className={
                        (!collapseMenuRight ? 'col-xxl-9 col-xl-8 col-lg-7 col-md-12' : '') +
                        ' col-12 content-body content-instrucoes'
                      }
                    >
                      <div className="body">
                        <CardHeader className="d-flex align-items-center">
                          <CardTitle className="mb-0">Instruções</CardTitle>
                          {clienteAtual && clienteAtual.id !== 'todos' && (
                            <Link
                              to="/instrucoes/incluir-nova"
                              className={`${isPending ? 'd-none' : 'btn btn-primary ml-auto '}`}
                            >
                              <i className="fa fa-plus" />
                              <span className="d-none d-lg-inline-block">Incluir Nova Instrução</span>
                            </Link>
                          )}
                          {collapseMenuRight && (
                            <button
                              type="button"
                              onClick={() => setCollapseMenuRight(false)}
                              title="Filtros"
                              className="btn btn-outline-secondary btn-show-menu-right"
                              style={{ marginLeft: '30px' }}
                            >
                              <i className="fa fa-angle-left" />
                              <span>Filtros</span>
                            </button>
                          )}
                        </CardHeader>
                        <CardBody>
                          <SkeletonLoader
                            isPending={isPending}
                            width="100%"
                            height="40px"
                            count={8}
                            widthRandomness={0}
                          >
                            <div className="flex-row-reverse bd-highlight float-right">
                              <div
                                className={`form-inline selecao-itens animated ${
                                  itensCount ? 'fadeIn' : 'fadeOut'
                                }`}
                              >
                                <span className="selecao-itens-numero m-2 half-rounded-circle">
                                  {itensCount}
                                </span>
                                Ítens selecionados
                                <select
                                  className="form-control ml-2"
                                  disabled={itensCount === 0}
                                  onChange={handlerSelectAction}
                                  value="nenhuma"
                                >
                                  <option value="nenhuma">Ação</option>
                                  <option value="emitir_boleto">Emitir Boleto</option>
                                  <option value="cancelar">Cancelar</option>
                                </select>
                              </div>
                            </div>
                            <Grid
                              keyField="codigoRow"
                              data={instrucoes}
                              configuracaoColunas={instrucoesGrid(
                                handleCheck,
                                checkedAll,
                                handleCheckAll,
                                toggleModalParecerInstrucao,
                                toggleModalAnexoInstrucao,
                                clienteAtual
                              )}
                              mensagem={message}
                              classes="table-layout-auto"
                              classeIdentificadora="listagem-instrucoes"
                            />
                          </SkeletonLoader>
                        </CardBody>
                      </div>
                    </div>
                    {!collapseMenuRight && (
                      <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-12 col-12 fixed-side-info">
                        <Form>
                          <div className="fixed-side">
                            <div
                              className="header cursor-pointer"
                              onClick={() => setCollapseMenuRight(true)}
                              onKeyPress={() => setCollapseMenuRight(true)}
                              role="button"
                              tabIndex={0}
                            >
                              <h4 className="title">
                                Filtros
                                <i className="fa fa-angle-right float-right" />
                              </h4>
                            </div>
                            <div className="body">
                              <SkeletonLoader
                                isPending={isPending}
                                width="98%"
                                height="20px"
                                count={8}
                                widthRandomness={0}
                              >
                                <div className="container p-0">
                                  <FormGroup>
                                    <Label>Cliente:</Label>
                                    <p className="form-control-static">
                                      {clienteAtual &&
                                        (clienteAtual.razaoSocial || clienteAtual.nomeFantasia)}
                                    </p>
                                  </FormGroup>

                                  {mostrarFiltroPlataforma && lstVerticais && (
                                    <div className="row">
                                      <div className="col-md-12">
                                        <Field
                                          label="Vertical"
                                          id="cod_vertical"
                                          name="cod_vertical"
                                          className="select-plataforma-relatorios"
                                          options={lstVerticais}
                                          onChange={e => {
                                            setVertical(e.value);
                                          }}
                                          disabled={isSubmitting || lstVerticais.length === 1}
                                          component={Select}
                                          autoComplete="off"
                                          isClearable={false}
                                          placeholder="-- Selecione"
                                        />
                                      </div>
                                    </div>
                                  )}

                                  {mostrarFiltroPlataforma && lstPlataformasFiltradas && (
                                    <div className="row">
                                      <div className="col-md-12">
                                        <Field
                                          label="Plataforma"
                                          id="cod_plataforma"
                                          name="cod_plataforma"
                                          className="select-plataforma-relatorios"
                                          options={lstPlataformasFiltradas}
                                          onChange={e => {
                                            setPlataforma(e.value);
                                          }}
                                          disabled={isSubmitting || lstPlataformasFiltradas.length === 1}
                                          component={Select}
                                          autoComplete="off"
                                          isClearable={false}
                                          placeholder="-- Selecione"
                                        />
                                      </div>
                                    </div>
                                  )}

                                  {mostrarFiltroPlataforma &&
                                    lstPlataformasFiltradas &&
                                    lstFiltradaUnidadeNegocio &&
                                    unidadeNegocio && (
                                      <div className="row">
                                        <div className="col-md-12">
                                          <Field
                                            label="Unidade de Negócio"
                                            id="cod_unidade_negocio"
                                            name="cod_unidade_negocio"
                                            className="select-plataforma-relatorios"
                                            options={lstFiltradaUnidadeNegocio}
                                            onChange={e => {
                                              setUnidadeNegocio(e.value);
                                            }}
                                            disabled={
                                              isSubmitting || lstFiltradaUnidadeNegocio.length === 1
                                            }
                                            component={Select}
                                            autoComplete="off"
                                            isClearable={false}
                                            placeholder="-- Selecione"
                                          />
                                        </div>
                                      </div>
                                    )}

                                  {mostrarFiltroPlataforma &&
                                    lstPlataformasFiltradas &&
                                    lstFiltradaGerentePlataforma &&
                                    gerente && (
                                      <div className="row">
                                        <div className="col-md-12">
                                          <Field
                                            label="Gerente"
                                            id="cod_gerente"
                                            name="cod_gerente"
                                            className="select-plataforma-relatorios"
                                            options={lstFiltradaGerentePlataforma}
                                            onChange={e => {
                                              setGerente(e.value);
                                            }}
                                            disabled={
                                              isSubmitting || lstFiltradaGerentePlataforma.length === 1
                                            }
                                            component={Select}
                                            autoComplete="off"
                                            isClearable={false}
                                            placeholder="-- Selecione"
                                          />
                                        </div>
                                      </div>
                                    )}

                                  <div className="row">
                                    <div className="col-md-12">
                                      <Field
                                        label="Tipo de Instrução"
                                        id="operationType"
                                        name="tipo_instrucao"
                                        options={tiposInstrucao}
                                        component={Select}
                                        disabled={isSubmitting}
                                        placeholder="-- Selecione"
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <Label>Período Inclusão</Label>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-xl-6 col-lg-12 col-md-6 col-12 col-data-inicio">
                                      <FastField
                                        name="data_inicio"
                                        id="start"
                                        disableWeekend={false}
                                        disabled={isSubmitting}
                                        component={DateTimePicker}
                                        autoComplete="off"
                                      />
                                    </div>
                                    <div className="col-xl-6 col-lg-12 col-md-6 col-12 col-data-final">
                                      <FastField
                                        name="data_final"
                                        id="end"
                                        disableWeekend={false}
                                        disabled={isSubmitting}
                                        component={DateTimePicker}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <Field
                                        label="Status"
                                        id="status"
                                        name="status"
                                        options={[
                                          { value: 1, label: 'Aprovada' },
                                          { value: 2, label: 'Em Análise' },
                                          { value: 3, label: 'Recusada' },
                                          { value: 4, label: 'Recusada - Cancelada pelo cliente' }
                                        ]}
                                        component={Select}
                                        disabled={isSubmitting}
                                        placeholder="-- Selecione"
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <FastField
                                        type="text"
                                        helper="(Instrução1, Instrução2, …)"
                                        label="Nr. Instruções"
                                        name="instrucao"
                                        id="instrucao"
                                        disabled={isSubmitting}
                                        component={Input}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <FastField
                                        type="text"
                                        helper="(Documento1, Documento2, …)"
                                        label="Documentos"
                                        name="documento"
                                        id="Documentos"
                                        disabled={isSubmitting}
                                        component={Input}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <FastField
                                        type="text"
                                        helper="Nome do Sacado"
                                        label="Sacado"
                                        name="sacado"
                                        id="sacado"
                                        disabled={isSubmitting}
                                        component={Input}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </SkeletonLoader>
                            </div>
                            <div className="bottom">
                              <Button color="primary" type="submit" block disabled={isPending}>
                                Filtrar
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            );
          }}
        />

        {modalParecerInstrucao && modalParecerInstrucao.show && (
          <ModalParecerInstrucao
            modal={modalParecerInstrucao}
            toggle={toggleModalParecerInstrucao}
            message="parecerInstrucao"
          />
        )}

        {modalAnexoInstrucao && modalAnexoInstrucao.show && (
          <ModalAnexoInstrucao
            modal={modalAnexoInstrucao}
            toggle={fecharModalInstrucao}
            setCorBtnAnexo={setCorBtnAnexo}
            enviarEmailAnexo={enviarEmailAnexo}
          />
        )}
      </Main>

      <Modal isOpen={modalCancel.show} toggle={modalCancelToggle} className="delete-modal">
        <ModalHeader toggle={modalCancelToggle}>Cancelar Instrução</ModalHeader>
        <ModalBody>Você tem certeza que deseja cancelar esta instrução?.</ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button color="primary" outline={true} onClick={modalCancelToggle}>
            Fechar
          </Button>
          <Button color="primary" onClick={modalCancelConfirm}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      {modalBoleto.show && (
        <Modal
          isOpen={modalBoleto.show}
          toggle={() => setModalBoleto({ show: false })}
          className="boleto-modal"
        >
          <ModalHeader toggle={() => setModalBoleto({ show: false })}>Boletos</ModalHeader>
          <ModalBody className="text-center">
            <SkeletonLoader
              isPending={modalBoleto.isPending}
              width="100%"
              height="50px"
              count={4}
              widthRandomness={1}
            >
              {(modalBoleto.data || []).map(boleto => {
                return (
                  <div key={boleto.instrucao} className="card-deck">
                    {boleto.mensagemRetorno && (
                      <div className="card mb-3">
                        <div className="card-body">
                          <h5 className="card-title">{`Instrução: ${boleto.instrucao}`}</h5>
                          <div className="col-md-12 decricao">{boleto.mensagemRetorno}</div>
                        </div>
                      </div>
                    )}
                    {!boleto.mensagemRetorno && (
                      <div className="card mb-3">
                        <div className="card-body">
                          <h5 className="card-title">{`Instrução: ${boleto.instrucao}`}</h5>
                          <div className="col-md-12 valor">{moneyFullFormat(boleto.boletoValor)}</div>
                          <div className="col-md-12 vencimento">
                            vencimento&nbsp;
                            {dateFormat(boleto.boletoDataVencimento)}
                          </div>
                          <div className="col-md-12 decricao">
                            Utilize o número do código de barras abaixo
                            <br />
                            para realizar o pagamento:
                          </div>
                          <div className="col-md-12 boleto">{boleto.boletoNumero}</div>
                          <div>
                            <Button
                              color="primary"
                              onClick={e => {
                                e.preventDefault();
                                copyToClipboard(boleto.boletoNumero);
                                swal({
                                  icon: 'success',
                                  text: 'Código de barras copiado com sucesso!'
                                });
                              }}
                            >
                              Copiar código de barras
                            </Button>
                            {boleto.instrucao && (
                              <Button
                                color="primary"
                                className="ml-2"
                                onClick={e => {
                                  e.preventDefault();
                                  window.open(
                                    boleto.boletoUrl,
                                    `Instrução ${boleto.instrucao}`,
                                    'resizable,scrollbars,status'
                                  );
                                }}
                              >
                                Visualizar Boleto
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </SkeletonLoader>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button color="primary" outline={true} onClick={() => setModalBoleto({ show: false })}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <HistoricoAcesso codTela={1177} />
    </>
  );
};

export default Instrucoes;
