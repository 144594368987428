import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import './styles.scss';

export default function Footer() {
  return (
    <Container className="footer-content">
      <Box className="footer-powered">
        <Typography className="descricao">
          Copyright &copy;
          {new Date().getFullYear()}
          .&nbsp;SaaS powered by ONE7. Todos os direitos reservados
        </Typography>
      </Box>
    </Container>
  );
}
