import React, { useState } from 'react';
import { Box, Card, CardContent, Grid, Tab, Tabs, Typography } from '@mui/material';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Check } from '@mui/icons-material';
import LoaderMain from '../../../Onboarding/shared/LoaderMain';
import DashLimitesCarteira from './DashLimitesCarteira';

const LimitesCarteira = ({ filtroDashboard, loadingDashFim }) => {
  moment.locale('pt-br');
  const [dadosChart, setDadosChart] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [abaAtiva, mudarAba] = useState('comercial');

  const trocarAba = (event, newValue) => {
    mudarAba(newValue);
  };

  return (
    <Card className="chart-card">
      {dadosChart ? (
        <CardContent
          className="ajuste-spin-chart ajuste-heigth-limites"
          sx={isPending ? { position: 'relative' } : {}}
        >
          {isPending && <LoaderMain tipoLoader="float" tipoImagem="circulo" />}
          <Typography
            variant="subtitle1"
            className="texto-cor-cinza-escuro pad-5 word-break labelChartLimiteCarteira"
          >
            <span className="font-size-1rem margin-right-10 texto-weight-600">Limites</span>
          </Typography>

          <Grid container className="margin-topbot-15 items-centro-verticalmente">
            <Grid item xl={3} lg={4} md={5} sm={12} xs={12}>
              <Tabs
                value={abaAtiva}
                onChange={trocarAba}
                variant="fullWidth"
                className="hide-tabs-indicator preencher-fundo-selected"
              >
                <Tab
                  item="comercial"
                  value="comercial"
                  className="borda-radius-left borda-1 font-bold sem-uppercase texto-cor-cinza-3"
                  icon={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <Check className="font-size-1-1-rem margin-neg-top-2 margin-right-5 font-bold selected-cor-principal" />
                      <Box className="flex-box-wrap flex-direction-column">
                        <Typography
                          variant="subtitle1"
                          className="font-size-1-rem texto-centro selected-cor-principal line-height-1 font-bold"
                        >
                          Comercial
                        </Typography>
                      </Box>
                      {isPending && (
                        <Box sx={{ pl: '10px', mr: '-50px' }}>
                          <LoaderMain tipoLoader="apenas_loader" tipoImagem="circulo" tamanho="md" />
                        </Box>
                      )}
                    </>
                  }
                />
                <Tab
                  item="credito"
                  value="credito"
                  className="borda-radius-right borda-1 font-bold sem-uppercase texto-cor-cinza-3"
                  icon={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <>
                      <Check className="font-size-1-1-rem margin-neg-top-2 margin-right-5 font-bold selected-cor-principal" />
                      <Box className="flex-box-wrap flex-direction-column">
                        <Typography
                          variant="subtitle1"
                          className="font-size-1-rem texto-centro selected-cor-principal line-height-1 font-bold"
                        >
                          Crédito
                        </Typography>
                      </Box>
                    </>
                  }
                />
              </Tabs>
            </Grid>

            <DashLimitesCarteira
              filtroDashboard={filtroDashboard}
              loadingDashFim={loadingDashFim}
              parentAbaAtiva={abaAtiva}
              parentSetIsPending={setIsPending}
              parentSetDadosChart={setDadosChart}
            />
          </Grid>
        </CardContent>
      ) : (
        <CardContent>
          <div className="error">
            <h1>Ops!</h1>
            <p>Alguma coisa deu errado, tente novamente mais tarde</p>
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default LimitesCarteira;
