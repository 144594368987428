import API from './api';

const searchByCPFOrCNPJ = term => {
  const params = {};
  if (term.length === 11) {
    params.cpf = term;
  } else if (term.length === 14) {
    params.cnpj = term;
  }

  return API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/sacados`, { params });
};

const searchNamehByCPFOrCNPJ = term => {
  const params = {};
  if (term.length === 11) {
    params.cpf = term;
  } else if (term.length === 14) {
    params.cnpj = term;
  }

  return API.get(`${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/tools/buscar-pessoa`, { params });
};

const getAnaliseOperacao = cnpjCliente => {
  return API.get(
    `${process.env.REACT_APP_API_OPERACAO_BASE_URL}/operacoes/quantidade-operacao-analise/${cnpjCliente}`
  );
};

const getDocumentosReprovadosCliente = cnpjCliente => {
  return API.get(
    `${process.env.REACT_APP_API_CADASTRO_BASE_URL}/documentos/quantidade-documentos-reprovados/${cnpjCliente}`
  );
};

const searchContasViaJornadaDigital = term => {
  const params = {};
  if (term.length === 11) {
    params.cpf = term;
  } else if (term.length === 14) {
    params.cnpj = term;
  }

  return API.get(
    `${process.env.REACT_APP_API_BASE_AREA_CLIENTE_PATH}/buscar-conta-terceiros-cadastro-jornada-digital`,
    { params }
  );
};

const getLimitesDisponiveisCliente = () => {
  return API.get(
    `${process.env.REACT_APP_API_OPERACAO_BASE_URL}/clientes/home-lista-limites-disponiveis`
  );
};

const getTitulosPendenciasCliente = () => {
  return API.get(
    `${process.env.REACT_APP_API_OPERACAO_BASE_URL}/clientes/home-lista-titulos-pendencias`
  );
};

const getContatosParceiro = () => {
  return API.get(`${process.env.REACT_APP_API_CADASTRO_BASE_URL}/empresas/contatos-empresa`);
};

export {
  searchByCPFOrCNPJ,
  searchNamehByCPFOrCNPJ,
  getAnaliseOperacao,
  getDocumentosReprovadosCliente,
  searchContasViaJornadaDigital,
  getLimitesDisponiveisCliente,
  getTitulosPendenciasCliente,
  getContatosParceiro
};
